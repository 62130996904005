import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getContractAsync, postMemberContractManageInviteAsync } from "src/api/contract/contract-api";
import { ContractModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseTextInputWithButton, ConfirmModal } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { InviteResult } from "../user-types";
import * as xlsx from "xlsx";
import FileUploadButton from "./components/FileUploadButton";
import InviteLoadingModal from "./components/InviteLoadingModal";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";
import { format } from "libphonenumber-js";
import { useMediaQueries } from "src/pages/hooks/media-query";

type InviteUserFormData = {
  phoneNumber: string;
  inviteUserList: Array<{ phoneNumber: string }>;
};

interface SheetData {
  [key: string]: string;
}

type Modal = {
  isOpen: boolean;
  type?: "INVITE_FAIL_MAX_COUNT" | "INVITE_SUCCESS" | "INVITE_FAIL" | "FILE_UPLOAD";
};

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 이용자 목록 > 이용자 초대
 */
const UserInvite = () => {
  const { isMobile: is_Mobile } = useMediaQueries();
  const navigate = useNavigate();

  // path variable 계약 id
  const { contractId } = useParams();

  // 신청/계약 상세 조회 api
  const { executeAsync: getContract } = useApiOperation(getContractAsync);

  // 이용자 초대 api
  const { executeAsync: postMemberContractManageInvite } = useApiOperation(postMemberContractManageInviteAsync, { noHandleError: true });

  // 신청/계약 상세
  const [contract, setContract] = useState<ContractModel | null>(null);

  // 확인 버튼만 있는 알림 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  // 확인 취소 버튼 있는 확인 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  // 파일(엑셀) 업로드 모달
  const [inviteLoadingModal, setInviteLoadingModal] = useState<Modal>({ isOpen: false });

  // 이용자 목록 초대 중 여부
  const [isLoadingInviteUsers, setIsLoadingInviteUsers] = useState<boolean>(false);

  // 이용자 초대 결과
  const [inviteResults, setInviteResults] = useState<InviteResult[]>([]);

  const defaultValues = useMemo(() => {
    return {
      phoneNumber: "",
      inviteUserList: [],
    };
  }, []);

  const { handleSubmit, register, control, setError, resetField } = useForm<InviteUserFormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    register("phoneNumber", {
      required: "휴대폰 번호를 입력해주세요",
      validate: {
        validatePhoneNumber: (phoneNumber: string) => {
          return isPossiblePhoneNumber(phoneNumber, "KR") || "올바르지 않은 휴대폰 번호입니다";
        },
      },
    });
  }, [register]);

  // 신청/계약 상세 조회
  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data } = await getContract({ contractId });
      setContract(data?.data || null);
    },
    [getContract],
  );

  useEffect(() => {
    if (contractId) {
      fetchContract(contractId);
    }
  }, [contractId, fetchContract]);

  const {
    fields: inviteUserList,
    append: appendInviteUser,
    remove: removeInviteUser,
    replace: replaceInviteUser,
  } = useFieldArray({
    control,
    name: "inviteUserList",
  });

  // 초대하기 버튼 클릭
  const clickUserInvite = useCallback(() => {
    if (Number(contract?.memberMaxNums || 0) < Number(contract?.memberTotCount || 0) + inviteUserList.length) {
      // 최대 이용자수 초과시 alert
      setAlertModal({ isOpen: true, type: "INVITE_FAIL_MAX_COUNT" });
      return;
    }
    setConfirmModal({ isOpen: true });
  }, [contract, inviteUserList]);

  // 이용자 초대
  const memberContractManageInvite = useCallback(
    async (phoneNumber: string) => {
      if (!contract?.contractManageId) throw Error("contractManageId is not found");

      try {
        const parsedPhoneNumber = parsePhoneNumber(format(phoneNumber, "KR", "NATIONAL"), "KR");

        console.log(parsedPhoneNumber);

        if (!isPossiblePhoneNumber(phoneNumber, "KR") || parsedPhoneNumber.getType() !== "MOBILE") {
          const _inviteResult: InviteResult = {
            type: "FAIL",
            phoneNumber: phoneNumber,
          };

          return _inviteResult;
        }

        const { status, data } = await postMemberContractManageInvite({
          contractManageId: Number(contract.contractManageId),
          contractMemberList: [{ inviteMobileNumber: phoneNumber }],
        });
        if (status === 200 && !data?.meta?.errorCode) {
          const _inviteResult: InviteResult = {
            type: "SUCCESS",
            phoneNumber: phoneNumber,
          };

          return _inviteResult;
        } else {
          const _inviteResult: InviteResult = {
            type: "FAIL",
            phoneNumber: phoneNumber,
            failErrorCode: data.meta.errorCode,
          };

          return _inviteResult;
        }
      } catch (error: any) {
        const _inviteResult: InviteResult = {
          type: "FAIL",
          phoneNumber: phoneNumber,
          failErrorCode: error?.response?.data?.meta?.errorCode,
        };

        return _inviteResult;
      }
    },
    [contract, postMemberContractManageInvite],
  );

  // 확인 모달 확인 버튼 클릭
  const clickModalConfirm = useCallback(() => {
    setConfirmModal({ isOpen: false });
    setInviteLoadingModal({ isOpen: true });
    if (inviteUserList.length === 0) throw Error("inviteUserList is not found");

    setIsLoadingInviteUsers(true);

    const _inviteResult: InviteResult[] = [];
    let delay = 100;

    const promiseArray = inviteUserList.map((inviteUser, index) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            memberContractManageInvite(inviteUser.phoneNumber).then((result) => {
              _inviteResult.push(result);
              setInviteResults((prev) => [...prev, result]);
            }),
          );
        }, delay * index);
      });
    });

    Promise.allSettled(promiseArray)
      .then((res) => {
        setInviteLoadingModal({ isOpen: false });
        // 초대완료 후 띄우는 알림 모달 (실패 건수 있을 경우 or 없을 경우)
        if (_inviteResult.filter((inviteResult) => inviteResult.type === "FAIL").length > 0) {
          setAlertModal({ isOpen: true, type: "INVITE_FAIL" });
        } else {
          setAlertModal({ isOpen: true, type: "INVITE_SUCCESS" });
        }
      })
      .finally(() => {
        setIsLoadingInviteUsers(false);
      });
  }, [inviteUserList, memberContractManageInvite]);

  // 파일이 업로드될 때 실행되는 함수
  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();
      const data = await new Promise<string | ArrayBuffer>((resolve, reject) => {
        reader.onload = (event) => {
          resolve(event.target?.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsArrayBuffer(file);
      });

      const workbook = xlsx.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(sheet) as SheetData[];
      const jsonKey = Object.keys(json[0])[0];

      const phoneNumbers = json.map((row) => row[jsonKey]).map((phoneNumber) => ({ phoneNumber: String(phoneNumber) }));

      // .filter((phoneNumber) => isPossiblePhoneNumber(phoneNumber, "KR"))
      // .map((phoneNumber) => format(phoneNumber, "KR", "E.164"));

      replaceInviteUser(phoneNumbers);
    } catch (error) {
      console.error(error);
    }
  };

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback((data: InviteUserFormData, e?: any) => {
    e.preventDefault();
  }, []);

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    return false;
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const isMobile = useMemo(() => {
    let flag = true;
    if ((window as any).ReactNativeWebView) {
      flag = false;
    } else {
      console.log("is_Mobile", is_Mobile);
      if (is_Mobile) {
        flag = false;
      } else {
        flag = true;
      }
    }
    return flag;
  }, [is_Mobile]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="CLOSE"
        onClickCloseButton={() => {
          navigate(-1);
        }}
      />

      <div className="user-invite-page">
        <div className="user-invite-title">
          <h1 className="base-title">이용자 초대</h1>
        </div>
        <div>
          <input ref={fileRef} onChange={onFileUpload} type="file" name="file" accept=".xlsx" required hidden />
          {isMobile && <FileUploadButton title={"엑셀 업로드"} onClick={() => fileRef.current?.click()} />}
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <article className="user-invite-add with-backward-floating">
            <div className="title-wrap">
              <h1>
                추가하려는 이용자의
                <br />
                휴대폰 번호를 입력해주세요.
              </h1>
            </div>

            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <>
                  <BaseTextInputWithButton
                    type="phone"
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="휴대폰 번호를 입력하세요."
                    // maxLength={13}
                    maxLength={17}
                    actionButtonTitle="추가하기"
                    onClickActionButton={() => {
                      const convertedValue = format(value, "KR", "NATIONAL");
                      if (!inviteUserList.find((inviteUser) => inviteUser.phoneNumber === convertedValue)) {
                        // 중복이 아닐 경우에만
                        appendInviteUser({ phoneNumber: convertedValue });
                        resetField("phoneNumber");
                      } else {
                        setError("phoneNumber", { message: "이미 추가된 번호입니다." });
                      }
                    }}
                    disabled={isLoadingInviteUsers}
                    disabledActionButton={!value || !!error || isLoadingInviteUsers}
                    isInvalid={!!error}
                    invalidMessage={error?.message}
                  />
                  <div className={!!error ? "mt40" : ""}></div>
                </>
              )}
            ></Controller>

            <div className="user-invite-add__invite-btn-wrap">
              <BaseButton
                type="button"
                title={`초대하기(${inviteUserList.length}명)`}
                className="base-btn size-small"
                onClick={() => {
                  clickUserInvite();
                }}
                disabled={inviteUserList.length === 0}
                isLoading={isLoadingInviteUsers}
              />
            </div>

            {inviteUserList.map((inviteUser, index: number) => (
              <div key={inviteUser.id} className={"added-user-line"}>
                <p>{inviteUser.phoneNumber}</p>
                <button
                  type="button"
                  className={"clear-btn"}
                  onClick={() => {
                    removeInviteUser(index);
                  }}
                  disabled={isLoadingInviteUsers}
                />
              </div>
            ))}
          </article>
          <div className="base-floating-btn-wrap">
            <BaseButton
              type="button"
              title={`초대하기(${inviteUserList.length}명)`}
              className="base-btn"
              onClick={() => {
                clickUserInvite();
              }}
              disabled={inviteUserList.length === 0}
              isLoading={isLoadingInviteUsers}
            />
          </div>
        </form>
        {/* 초대하기 메시지 발송 중 로딩 모달 */}
        <InviteLoadingModal isOpen={inviteLoadingModal.isOpen} current={inviteResults.length} total={inviteUserList.length} />
        {/* 초대하기 클릭시 띄우는 컨펌 모달 */}
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setConfirmModal({ isOpen: false })}
          onClick={() => clickModalConfirm()}
          className="pa20 pt30"
        >
          <div>
            <p className="font18 font-weight-600">초대할까요?</p>
            <p className="font15 font-weight-400 mt20">해당 이용자에게 앱 설치 알림톡이 발송됩니다.</p>
            <p className="font15 font-weight-400 mt5">회원 가입을 완료하면 공간 이용 권한이 부여됩니다.</p>
          </div>
        </ConfirmModal>
        {/* 최대 이용자수 초과 alert */}
        {alertModal.type === "INVITE_FAIL_MAX_COUNT" && (
          <ConfirmModal
            isOpen={alertModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setAlertModal({ isOpen: false });
            }}
          >
            <div>
              <p className="font18 font-weight-600">최대 이용자 수를 초과하였습니다.</p>
              <p className="mt20 font15 font-weight-400">이용자를 추가하시려면 등록된 이용자 삭제 후 다시 시도해 주세요.</p>
            </div>
          </ConfirmModal>
        )}
        {/* 이용자 초대 중 실패한 초대 있을 경우 alert */}
        {alertModal.type === "INVITE_FAIL" && (
          <ConfirmModal
            isOpen={alertModal.isOpen}
            btnLeftTitle={"확인"}
            btnRightTitle={"실패 사유 보기"}
            onClose={() => {
              setInviteResults([]);
              setAlertModal({ isOpen: false });
            }}
            onClick={() => {
              navigate(`/court/mypage/contracts/${contractId}/users/invite/fail`, {
                state: {
                  inviteResults,
                },
              });
            }}
          >
            <div>
              <p className="font18 font-weight-600">초대를 완료했어요.</p>
              <p className="mt20 font15 font-weight-400">
                성공 {inviteResults.filter((inviteResult) => inviteResult.type === "SUCCESS").length}건 / 실패{" "}
                {inviteResults.filter((inviteResult) => inviteResult.type === "FAIL").length}건
              </p>
            </div>
          </ConfirmModal>
        )}
        {/* 이용자 초대 모두 성공했을 경우 alert */}
        {alertModal.type === "INVITE_SUCCESS" && (
          <ConfirmModal
            isOpen={alertModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setInviteResults([]);
              setAlertModal({ isOpen: false });
              navigate(-1);
            }}
          >
            <div>
              <p className="font18 font-weight-600">초대를 완료했어요.</p>
              <p className="mt20 font15 font-weight-400">성공 {inviteResults.filter((inviteResult) => inviteResult.type === "SUCCESS").length}건</p>
            </div>
          </ConfirmModal>
        )}
      </div>
    </>
  );
};
export default UserInvite;

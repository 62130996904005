// import moment from "moment";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { PriceModel, PriceParams } from "src/api/product/product-types";
import { getPriceAsync, getReservedTimesAsync, postQuotationAsync } from "src/api/reservation/reservation-api";
import { AcknowledgeReservation, QuotationRequest, ScheduleData } from "src/api/reservation/reservation-type";
import BaseShareButton from "src/components/BaseShareButton";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import TaapSpaceBuilding from "src/pages/building/components/TaapSpaceBuilding";
import SwiperViewer from "src/pages/components/SwiperViewer";
import { useToast } from "src/pages/hooks/toast";
import { YmdFormat, numberToStringWithComma, onChangeKoreanDays } from "src/utils/common-util";
import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";
import useProductFacility from "../hooks/useProductFacility";
import FacilityReservation from "./components/FacilityReservation";

/**
 * 공용공간 상세
 */

SwiperCore.use([Parallax, Navigation, Pagination]);

const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

const FacilityDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { productId, facilityId } = useParams();

  // 상품/공용공간 상세 hook
  const { facility, product, building, mediaSorting } = useProductFacility({ productId: String(productId), facilityId: String(facilityId) });

  const [calendardate, setCalendarDate] = useState<string>(moment().format(YmdFormat.WITH_TIME_ZONE));

  const [reserved, setReserved] = useState<AcknowledgeReservation[]>([]);
  // 요금 목록
  const [prices, setPrices] = useState<PriceModel[]>([]);

  // 휴일 목록
  // const [holidays, setHolidays] = useState<HolidayPeriodModel[]>([]);

  // 시간 선택 스케줄표

  const scheduleList = useMemo(() => {
    // TODO: 휴뮤기간 & 예약관련 api 가 아직 분명한 데이터가 아니라 제외 - 추후 적용
    // const schedule = holidays.map((item: ScheduleData) => {
    //   const startDate = Math.floor(new Date(item.startDate).getTime() / 1000);

    //   const endDate = Math.floor(new Date(item.endDate).getTime() / 1000);

    //   prices.forEach((priceItem) => {
    //     if (item.slot === priceItem.slot) {
    //       item = { ...item, price: Number(priceItem.price) };
    //     }
    //   });

    //   reserved.forEach((reservationItem) => {
    //     const reserveStart = Math.floor(new Date(reservationItem.start).getTime() / 1000);
    //     const reserveEnd = Math.floor(new Date(reservationItem.end).getTime() / 1000);

    //     if (startDate >= reserveStart && endDate <= reserveEnd) {
    //       item = { ...item, isClosed: true };
    //     }
    //   });

    //   return item;
    // });

    const schedule = prices.map((item: ScheduleData) => {
      const startDate = Math.floor(new Date(item.startDate).getTime() / 1000);
      const endDate = Math.floor(new Date(item.endDate).getTime() / 1000);

      reserved.forEach((reservationItem) => {
        const reserveStart = Math.floor(new Date(reservationItem.start).getTime() / 1000);
        const reserveEnd = Math.floor(new Date(reservationItem.end).getTime() / 1000);

        if (startDate >= reserveStart && endDate <= reserveEnd) {
          item = { ...item, isClosed: true };
        }
      });

      return item;
    });

    return schedule;
  }, [prices, reserved]);

  // 스케줄표에서 선택한 시간들
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleData[]>([]);

  // scheduleList 에서 가장 낮은 금액
  const minimumPrice = useMemo(() => {
    return scheduleList.reduce((min, current) => (current.price && min.price && current.price < min.price ? current : min), scheduleList[0]);
  }, [scheduleList]);

  const { openToast } = useToast();

  // 요금조회 api
  const { executeAsync: getPrices } = useApiOperation(getPriceAsync, { noAuthenticationRequired: true });

  // 견적서 등록 api
  const { executeAsync: postQuotation } = useApiOperation(postQuotationAsync, { noAuthenticationRequired: true });

  // 휴무기간 api
  // const { executeAsync: getHolidaysPeriod } = useApiOperation(getHolidaysPeriodAsync, { noAuthenticationRequired: true });

  // 스케쥴 예약 점유확인 api
  const { executeAsync: getReservedTimes } = useApiOperation(getReservedTimesAsync, { noAuthenticationRequired: true });

  // 요금 가져오기
  const fetchPrices = async (selectedDate: string) => {
    setCalendarDate(selectedDate);

    const priceData: PriceParams = {
      providerId: String(product?.providerId),
      serviceId: String(product?.id),
      serviceType: "SERVICE_PRODUCT",
      startDate: moment(selectedDate).startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      endDate: moment(selectedDate).add(1, "days").startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      buildingCommonFacilityId: String(facility?.id),
    };

    const { data, status } = await getPrices(priceData);
    if (status >= 200 && status <= 299) {
      setPrices(data.data.content);
    }
  };

  useEffect(() => {
    if (product?.id) {
      fetchPrices(calendardate);
      // fetchHoliday();
      fetchReservation();
    }
  }, [product?.id, calendardate]);

  // 휴무기간 조회
  // const fetchHoliday = async () => {
  //   const paramsData: HolidayPeriodParams = {
  //     serviceId: String(product?.id || ""),
  //     serviceType: "SERVICE_PRODUCT",
  //     date: moment(calendardate).format(YmdFormat.WITH_TIME_ZONE),
  //     buildingCommonFacilityId: String(facility?.id || ""),
  //   };

  //   const { data, status } = await getHolidaysPeriod(paramsData);
  //   const holidays = data.data.content;
  //   if (status >= 200 && status <= 299) {
  //     setHolidays(holidays);
  //   }
  // };

  const fetchReservation = async () => {
    const { data, status } = await getReservedTimes({
      facilityId,
      start: moment(calendardate).startOf("day").format(YmdFormat.WITH_TIME_ZONE),
      end: moment(calendardate).add(1, "days").startOf("day").format(YmdFormat.WITH_TIME_ZONE),
    });

    if (status >= 200 && status <= 299) {
      setReserved(data.data.content);
    }
  };

  //
  const onSelectSchedules = (schedule: ScheduleData) => {
    const sortedSchedule = [...selectedSchedule, schedule].sort((a, b) => a.slot - b.slot); // 선택한 시간 slot 기준 으로 sorting

    const startSlot = sortedSchedule[0].slot; // 가장 빠른 시간
    const endSlot = sortedSchedule[sortedSchedule.length - 1].slot; // 가장 마지막 시간

    const getSchedule = scheduleList.filter((item) => item.slot >= startSlot && item.slot <= endSlot);

    // 비활성화 구간 선택될 시 선택 안되도록 적용
    if (getSchedule.some((item) => item.isClosed)) {
      setSelectedSchedule([schedule]);
      return;
    }

    setSelectedSchedule(getSchedule);

    if (selectedSchedule.length > 0 && selectedSchedule.find((item) => item.slot === schedule.slot)) {
      setSelectedSchedule([]);
    }
  };

  // 이용 신청하기 버튼 -> 견적서 등록
  const onAddQuotaion = async () => {
    if (selectedSchedule.length < 2) {
      openToast({ content: "최소 1시간 이상 예약 가능한 공간입니다" });
      return;
    }

    const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey!, { action: "court/quotation/create" });
    if (!token) return;

    const quotaionData: QuotationRequest = {
      token,
      quotationItemList: [
        {
          productId: String(product?.id),
          buildingCommonFacilityId: String(facility?.id),
          startDate: selectedSchedule[0].startDate, //

          endDate: selectedSchedule[selectedSchedule.length - 1].endDate,
        },
      ],
    };
    const { data, status } = await postQuotation(quotaionData);

    if (status >= 200 && status <= 299) {
      const result = data.data.content;
      navigate(`/court/quotation/${result.quotationNo}`);
    }
  };

  // 선택한 시간에 따른 총 비용
  const totalPrice = () => {
    if (selectedSchedule.length > 0) {
      const price = selectedSchedule.reduce((accumulator, time) => accumulator + time.price!, 0);
      const day = onChangeKoreanDays(selectedSchedule[0].endDate);
      const seletedTime = selectedSchedule.map((item) => {
        return { startDate: item.startDate, endDate: item.endDate };
      });
      const start = moment.min(seletedTime.map((time) => moment(time.startDate))).format("HH:mm");
      const end = moment.max(seletedTime.map((time) => moment(time.endDate))).format("HH:mm");
      const date = moment.min(seletedTime.map((time) => moment(time.startDate))).format("M월 D일");

      return (
        <div className="text-center font15 font-weight-400 mb10">
          <span className="">{`${numberToStringWithComma(price)}원`}</span>
          <span className="font13 font-weight-100 ml5">(부가세 별도)</span>
          <span className="mx5">|</span>
          <span className="">{date}</span>
          <span className="mx5">({day})</span>
          <span>{start}</span>
          {end && <span> ~ {end}</span>}
        </div>
      );
    }
  };

  return (
    <>
      <MetaTag title="공간상세" isReCAPTCHA={selectedSchedule.length < 2 ? false : true} />
      <Header headerType="BACK" title={facility?.facilityName} rightArea={<Link to="/court/main" className="home"></Link>} />

      <div className="facility-detail">
        <SwiperViewer medias={mediaSorting(facility?.mediaList || [])} />
        <article className="facility-info">
          {/* 메인정보 */}
          <section className="facility-info__main bottom-divider">
            <div className="main-wrap">
              <h2 className="main-title">{facility?.facilityName}</h2>
              <BaseShareButton />
            </div>
            <div className="sub-wrap">
              <div className="price-info">
                <p className="price">
                  <span>{(minimumPrice && numberToStringWithComma(minimumPrice.price)) ?? "-"}</span> 원~
                </p>
                <p className="info"> /30분 (부가세 별도)</p>
              </div>
              <p className="description">시간과 날짜에 따라 금액 상이</p>
            </div>
            <div className="additional-info">
              <div className="info-wrap">
                <div className="info-wrap__index">
                  <p>정원</p>
                </div>
                <div className="info-wrap__value">
                  <p>{facility?.facilityMaxPeopleNums}명</p>
                </div>
              </div>
              <div className="info-wrap">
                <div className="info-wrap__index">
                  <p>층정보</p>
                </div>
                <div className="info-wrap__value">
                  <p>{`${!facility?.isGround ? "B" : ""}${facility?.floorNum} 층`}</p>
                </div>
              </div>
            </div>
          </section>

          {/* 예약부분 */}
          {product?.productType === "TIME_COURT" && (
            <FacilityReservation
              date={calendardate}
              selectedDate={setCalendarDate}
              scheduleList={scheduleList}
              onSelectSchedules={onSelectSchedules}
              selectedSchedule={selectedSchedule}
            />
          )}

          {/* 공간정보 */}
          {facility?.spaceStyle !== "SPACE_STYLE_UNRECOGNIZED" && (
            <section className="facility-info__facility-info top-divider">
              <h2>공간 정보</h2>
              <div className="info-line">
                <span className="minmax90">시설안내</span>
                <p className="pre-formatted">{facility?.facilityList?.join(",")}</p>
              </div>
              <div className="info-line">
                <span className="minmax90">이용안내</span>
                <p className="pre-formatted">{facility?.description}</p>
              </div>
            </section>
          )}

          {/* 건물 정보 */}
          <TaapSpaceBuilding building={{ ...building }} isOverview />
        </article>
        {product?.productType === "TIME_COURT" ? (
          <div className="base-floating-btn-wrap border-top ">
            <div>{totalPrice && totalPrice()}</div>
            <div className="flex-center">
              <span
                className="minmax50 mr20 font15 cursor-pointer"
                style={{ borderBottom: "1px solid" }}
                onClick={() => {
                  const prevURL = process.env.REACT_APP_PPOINT_BASEURL + location.pathname;
                  const state = {
                    prevURL,
                  };
                  navigate(`/court/product/${product.id}/inquiry?facilityId=${facilityId}`, { state });
                }}
              >
                문의하기
              </span>
              <button className="base-btn" disabled={selectedSchedule.length < 1} onClick={onAddQuotaion}>
                예약하기
              </button>
            </div>
          </div>
        ) : (
          <div className="base-floating-btn-wrap">
            <button
              className="base-btn"
              onClick={() => {
                const prevURL = process.env.REACT_APP_PPOINT_BASEURL + location.pathname;
                const state = {
                  prevURL,
                };
                navigate(`/court/product/${product?.id}/inquiry?facilityId=${facilityId}`, { state });
              }}
            >
              문의하기
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default FacilityDetail;

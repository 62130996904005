import Header from "src/components/layout/Header";
import RefundPolicy from "./RefundPolicy";
import { ConfirmModal } from "src/components";
import { useState } from "react";
import { Modal } from "src/pages/visitor/visitor-types";

type Props = {
  onClose: Function;
};
const ServiceTypeRefundPolicy = ({ onClose }: Props) => {
  return (
    <>
      <Header
        headerType="CLOSE"
        title="환불규정"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <RefundPolicy />
    </>
  );
};

export default ServiceTypeRefundPolicy;

import moment from "moment";
import { useRef, useState } from "react";
import Swiper from "react-id-swiper";
import SwiperCore, { A11y, Navigation } from "swiper";
import SlideItem from "./components/SlideItem";
SwiperCore.use([Navigation, A11y]);

type Props = {
  onDateClick: (date: string) => void;
};
const SwiperCalendar = (props: Props) => {
  const today = moment();
  const [selectedDay, setSelectedDay] = useState(moment(today).format("YYYY/MM/DD"));
  const swiperRef = useRef(null);

  // moment.js를 사용하여 오늘 이후의 각 월을 생성
  const yearOfMonths = Array.from({ length: 12 }, (_, index) => {
    return moment(today).add(index, "month");
  });

  // const [currentSlideIndex, setCurrentSlideIndex] = useState(Number(moment(today).format("M")) - 1);

  const weeks = ["일", "월", "화", "수", "목", "금", "토"];
  return (
    <div className="swiper-calendar">
      <div className="calendar-container">
        <p className="swiper-calendar__date">{moment(selectedDay).format("YYYY년 M월 D일 (ddd)")}</p>
        <div className="week-header">
          {weeks.map((week, i) => (
            <div key={i}>{week}</div>
          ))}
        </div>
        {yearOfMonths && yearOfMonths.length > 0 && (
          <Swiper
            ref={swiperRef}
            // initialSlide={currentSlideIndex}
            initialSlide={0} // 새로운 "월"이 시작되면 그 "월" 의 페이지의 index가 0이 되는것 같음
            preventClicks={true}
            preventClicksPropagation={true}
            preventInteractionOnTransition={true}
            resistance={false}
            // threshold={250}
            a11y={{ enabled: false }}
          >
            {yearOfMonths.map((month, idx) => {
              return (
                <div key={idx}>
                  <SlideItem month={month} selectedDay={selectedDay} onDateClick={props.onDateClick} setSelectedDay={setSelectedDay} />
                </div>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};
export default SwiperCalendar;

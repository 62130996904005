import { useMemo } from "react";

const RefundPolicy = ({ type }: any) => {
  const text1 = `시간제 예약 상품에 한정하여 취소 규정은 이용일 기준으로 적용됩니다.`;
  const text2 = `이용기간 및 상품 예약 정보 변경을 위한 취소 시에도 동일한 취소 규정이 적용됩니다.`;
  const tableData: any = [
    { period: "이용일로부터 7일 전까지", refund: `0%` },
    { period: `이용일로부터 3일전까지`, refund: `50%` },
    { period: `이용일 당일 취소, 노쇼, 이용일시 경과 시 `, refund: `100%` },
  ];
  const title = useMemo(() => {
    if (type === "cancel") {
      return "취소 규정";
    } else {
      return "환불 규정";
    }
  }, [type]);
  return (
    <>
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <h2>{title}</h2>
            </div>
            <div>
              <ul className="ml10 mr10 pb10 font14 text-primary1">
                <li>{text1}</li>
                <li>{text2}</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <table className="horizontal-table">
            <thead>
              <tr>
                <th>기간</th>
                <th>취소 수수료</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <p>{data.period}</p>
                    </td>
                    <td>
                      <p>{data.refund}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;

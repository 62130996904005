import { ProductType } from "../product/product-types";
import { Sort } from "../public-types";

// 이용자 상태
export type MemberStatus = "MEMBER_INVITE" | "MEMBER_USE" | "MEMBER_DELETE" | "MEMBER_CLOSE" | "MEMBER_EXPIRE";

// 이용신청 진행 단계
export type ContractStep =
  | "APPLY_RECEIVED"
  | "APPLY_CANCEL"
  | "APPLY_CONFIRM"
  | "CONTRACT_PLAN"
  | "CONTRACT_ACCEPT"
  | "USE_APPROVAL"
  | "USE_PROGRESS"
  | "USE_COMPLETE"
  | "TERMINATE_RECEIVED"
  | "TERMINATE_COMPLETE";

// 신청유형 (VISIT : 방문희망, CONTRACT : 계약희망)
export type ContractApplyType = "VISIT" | "CONTRACT";

// 결제상태 (PAY_WAIT : 결제 대기, PAY_COMPLETE : 결제 완료)
export type PayStatus = "PAY_WAIT" | "PAY_COMPLETE";

// 결제상태(최신) (PAYMENT_READY : 결제대기, PAYMENT_FREE: 결제무료, PAYMENT_SUCCESS : 성공-결제성공, PAYMENT_FAIL : 실패-결제실패, PAYMENT_CANCEL : 취소-결제취소, CONTRACT_CANCEL : 해지-계약해지 빌링스케줄실행 중지)
export type BillPayStatus = "PAYMENT_READY" | "PAYMENT_FREE" | "PAYMENT_SUCCESS" | "PAYMENT_FAIL" | "PAYMENT_CANCEL" | "CONTRACT_CANCEL";

export function getMemberStatusText(memberStatus?: MemberStatus) {
  switch (memberStatus) {
    case "MEMBER_INVITE":
      return "초대완료";
    case "MEMBER_USE":
      return "사용중";
    case "MEMBER_DELETE":
      return "삭제";
    case "MEMBER_CLOSE":
      return "종료";
    case "MEMBER_EXPIRE":
      return "만료";
    default:
      return "";
  }
}

export function getContractStepText(contractStep?: ContractStep) {
  switch (contractStep) {
    case "APPLY_RECEIVED":
      return "신청접수";
    case "APPLY_CANCEL":
      return "신청취소";
    case "APPLY_CONFIRM":
      return "신청확인";
    case "CONTRACT_PLAN":
      return "계약예정";
    case "CONTRACT_ACCEPT":
      return "계약체결";
    case "USE_APPROVAL":
      return "이용승인";
    case "USE_PROGRESS":
      return "이용중";
    case "USE_COMPLETE":
      return "이용완료";
    case "TERMINATE_RECEIVED":
      return "해지접수";
    case "TERMINATE_COMPLETE":
      return "해지완료";
    default:
      return "";
  }
}

export const changeStatusText = (spaceProductType: string, contractStep: ContractStep) => {
  const text: string = getContractStepText(contractStep);
  if (spaceProductType !== "TIME_COURT") {
    return text;
  } else {
    if (text === "계약예정") {
      return "예약 대기";
    }
    if (text === "계약체결") {
      return "결제 대기";
    }
    if (text === "이용승인") {
      return "예약 완료";
    }
    // if (text === "해지접수") {
    //   return "취소 접수";
    // }
    // if (text === "해지완료") {
    //   return "취소 완료";
    // }
    return text;
  }
};

// 신청중 / 이용중 / 이용완료
export type ContractStepGroup = "APPLY" | "USE" | "COMPLETE";

// DEPOSIT_ACCOUNT: 보증금 계좌, RENT_ACCOUNT: 이용료 계좌, MANAGEMENT_ACCOUNT: 관리비 계좌
export type AccountType = "DEPOSIT_ACCOUNT" | "RENT_ACCOUNT" | "MANAGEMENT_ACCOUNT";

export function getContractStepGroup(contractStep?: ContractStep): ContractStepGroup | undefined {
  if (!contractStep) return undefined;
  if (["APPLY_RECEIVED", "APPLY_CONFIRM", "CONTRACT_PLAN", "CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contractStep)) {
    // 신청중
    return "APPLY";
  } else if (["USE_PROGRESS", "TERMINATE_RECEIVED"].includes(contractStep)) {
    // 이용중
    return "USE";
  } else if (["APPLY_CANCEL", "TERMINATE_COMPLETE", "USE_COMPLETE"].includes(contractStep)) {
    // 이용완료
    return "COMPLETE";
  }
}

export function getBillPayStatusText(billPayStatus?: BillPayStatus) {
  switch (billPayStatus) {
    case "PAYMENT_READY":
      return "결제대기";
    case "PAYMENT_FREE":
      return "결제무료";
    case "PAYMENT_SUCCESS":
      return "결제완료"; // 성공-결제성공
    case "PAYMENT_FAIL":
      return "결제실패";
    case "PAYMENT_CANCEL":
      return "결제취소";
    case "CONTRACT_CANCEL":
      return "계약해지";
    default:
      return "";
  }
}

export function getBillPayStatusColor(billPayStatus?: BillPayStatus) {
  switch (billPayStatus) {
    case "PAYMENT_READY":
      return "color-gray";
    case "PAYMENT_SUCCESS":
      return "";
    case "PAYMENT_FAIL":
      return "color-brown";
    case "PAYMENT_CANCEL":
      return "color-gray";
    case "CONTRACT_CANCEL":
      return "color-gray";
    default:
      return "";
  }
}

// 계약의 무료 여부 판단
export function isFreeContract(contract?: ContractModel | null): boolean | null {
  if (!contract) return null;
  return Number(contract?.payAmount || 0) === 0;
}

export type Pgcode = "creditcard" | "banktransfer" | "virtualaccount" | ""; // 결제 방법(수단) creditcard : 신용카드, virtualaccount : 가상계좌

// 계약의 이용자 목록 조회 parameter
export interface MemberContractManageListParams {
  contractManageId: string | number;
  searchType?: "ALL" | "MEMBER_NO" | "MEMBER_MOBILE_NUMBER";
  searchValue?: string;
  pageRequest?: {
    page?: number;
    size?: number;
    sort?: Sort;
  };
}

/**
 * 내가 이용자로 초대되어 있는 계약 목록 상세
 */
export interface MemberContractModel {
  contractManageId?: number; // 계약 관리 id
  contractApplyNumber?: string; // 이용신청 번호
  contractId?: number; // 계약정보 id
  contractMemberId?: number; // 이용자 id
  contractName: string; // 계약명
  memberNo?: string; // 회원번호
  mbOrganizationId?: number; // 조직(회사) id
  spaceProductId?: number; // 상품id
  spaceProductType?: ProductType; // 공간상품 타입
  spaceProductName?: string; // 상품명
  contractStep?: ContractStep; // 이용신청 진행 단계
  useTerm?: number; // (신청)이용 신청기간
  useStartTime?: string; // (신청)이용 시작 일시
  useEndTime?: string; // (신청)이용 종료 일시
  contractTerm?: number; // 계약기간
  contractStartTime?: string; // 계약 시작 일시(이용신청일 기준 2주 이내 선택 가능)
  contractEndTime?: string; // 계약 종료 일시
  inviteDate?: string; // 초대일시
  isContractor?: boolean; // 계약자 여부
  isAdmin?: boolean; // 관리자 여부
  memberStatus?: MemberStatus; // 이용자 상태
  memberTotCount?: number; // 공간상품 현재 이용자수
  memberMaxNums?: number; // 공간상품 최대 이용자수
  isUseMeetingRoom?: boolean; // (공용시설) 회의실 사용여부
  isUseRefreshRoom?: boolean; // (공용시설) 리프레쉬룸 사용여부
  isUseDesk?: boolean; // (공용시설) 데스크 사용여부
  isUseVisitorInvite?: boolean; // 방문자 초대 사용여부
}

/**
 * 계약의 이용자 목록 상세
 */
export interface MemberContractManageModel {
  contractMemberId?: number; // 이용자 id
  contractManageId?: number; // 계약 관리 id
  inviteMobileNumber?: string; // 초대 휴대폰번호
  memberNo?: string; // 회원번호
  isContractor?: boolean; // 계약자 여부
  isAdmin?: boolean; // 관리자 여부
  inviteDate?: string; // 초대일시
  memberStatus?: MemberStatus; // 이용자 상태 (초대/사용/삭제/종료)
  useTime?: number; // 사용시간 (T타입 한정)
  email?: string; // 이메일
  phoneNumber?: string; // 휴대폰 번호
}

/**신청계약 스케줄리스트 */

export interface ScheduleListModel {
  scheduleId?: string;
  contractId?: string;
  providerId?: string;
  supplyType?: string;
  billIssueDate?: string;
  billPayStatus?: string;
  scheduleStartTime?: string;
  scheduleEndTime?: string;
  regularPgcode?: Pgcode;
  payRegularMonth?: string;
  payRegularDay?: number;
  taxInvoiceIssueMonth?: string;
  taxInvoiceIssueDay?: number;
  isTaxInvoiceIssue?: boolean;
  cardInfo?: string;
  payInfo?: string;
  accountInName?: string;
  overdueChargeStandard?: string;
  isAutoBilling?: boolean;
  isAutoSend?: boolean;
  isBillkey?: boolean;
  sContractCancel?: boolean;
  contractCancelDate?: string;
  contractCancelStartOrder?: number;
  contractCancelReason?: string;
  recentBill?: ScheduleRecentBill;
}

export interface ScheduleRecentBill {
  billDueDate: string;
  billId: string;
  billIssueDate: string;
  billOrder: number;
  billPayStatus: string;
  billTitle: string;
  isVerifiedPaymentKey: boolean;
  pgcode: string;
  supplyAmount: number;
  taxAmount: number;
  totalAmount: number;
}

/**
 * 신청/계약 상세
 */
export interface ContractModel {
  contractId?: number; // 계약정보 id
  contractManageId?: number; // 계약관리 id
  memberNo?: string; // (신청)이용신청 회원번호
  applicantName?: string; // (신청)이용 신청자 이름
  applicantCorporation?: string; // (신청)이용 신청자 회사명
  contractApplyType?: ContractApplyType; // (신청)이용 신청유형 - F/O 인 경우 사용
  spaceProductId?: number; // 공간상품 id (공간상품 관리)
  spaceProductType?: ProductType; // 공간상품타입
  spaceProductName?: string; // 공간상품이름
  buildingId?: number; // 공간상품 대표건물 ID
  memberTotCount?: number; // 공간상품 현재 이용자수
  memberMaxNums?: number; // 공간상품 최대 이용자수
  isUseOmega?: boolean; // 한컴 오메가연동 사용여부
  contractApplyNumber?: string; // 이용신청 번호
  contractStep?: ContractStep; // 이용신청 진행 단계
  createdDate?: string; // 생성일(이용신청 일시)
  contractTerm?: number; // 계약기간
  contractStartTime?: string; // 계약 시작 일시(이용신청일 기준 2주 이내 선택 가능)
  contractEndTime?: string; // 계약 종료 일시
  useTerm?: number; // (신청)이용 신청기간
  useStartTime?: string; // (신청)이용 시작 일시
  useEndTime?: string; // (신청)이용 종료 일시
  contractNumber?: string; // 계약 번호
  contractDate?: string; // 계약일시
  depositAmount?: string; // 보증금
  earnestAmount?: string; // 보증금 (계약금)
  earnestPayStatus?: PayStatus; // 보증금 (계약금) 결제상태
  balanceAmount?: number; // 보증금 (잔금)
  balancePayStatus?: PayStatus; // 보증금 (잔금) 결제상태
  payAmount?: string; // 결제 금액
  isBillkey?: boolean; // billkey 유무
  payRegularDay?: number; // 정기 결제일 (일반결제/세금계산서 발행형 공통) F/O 타입에 한해 Response
  taxInvoiceIssueDay?: number; // 세금계산서 발행일 F/O 타입에 한해 Response
  isTaxInvoiceIssue?: boolean; // 세금계산서 발행여부 F/O 타입에 한해 Response
  // max?: BillPayStatus; // 결제상태(최신)
  billPayStatus?: BillPayStatus; // 결제상태(최신)
  overduePercentBasis?: number; // 연체 수수료(%) 기준
  contractMedia?: Array<CtMediaFile>;
  providerAccountList?: Array<CtProviderAccount>; // 프로바이더 계좌 컬렉션
  scheduleList: Array<ScheduleListModel>;
  quotationList?: Array<Quotation>; //견적서 목록 (2024-01-19 현재는)S2 상품에만 존재
}

/**S2 계약 갼적서 구성 */
export interface Quotation {
  quotationId: string;
  orderNum: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
  quotationNo: string;
}

export type CtMediaFileServiceType = "CT_PROVIDER" | "CT_CONTRACT_MANAGE" | "CT_CONTRACT" | "CT_PAYMENT";
export type CtMediaFileServiceTag = "CONTRACT_DOCUMENT" | "CONTRACT_REQUIRED" | "BUSINESS_LICENSE";
export interface CtMediaFile {
  id: number; // 계약서 템플릿 파일 id
  serviceId: number; // 서비스 id (ex. 계약테이블 > 계약 id)
  serviceType?: CtMediaFileServiceType; // 서비스타입 (CT_PROVIDER : 프로바이더, CT_CONTRACT_MANAGE : 계약 관리, CT_CONTRACT : 계약, CT_PAYMENT : 결제)
  serviceTag?: CtMediaFileServiceTag; // 서비스태그 (CONTRACT_DOCUMENT : 계약서, CONTRACT_REQUIRED : 계약필요서류, BUSINESS_LICENSE : 사업자등록증)
  isPrimary?: boolean; // 대표미디어여부
  orderNums?: number; // 순번
  contentType?: string; // 미디어타입
  filename?: string; // 원본미디어이름
  key?: string; // 미디어경로
  fileSize?: number; // 파일사이즈
  fileStorageType?: string; // 파일스토리지 타입
  isDeleted?: boolean; // 삭제여부
  createdDate?: string; // 등록일자
  createdBy?: string; // 등록자ID
  modifiedDate?: string; // 수정일자
  modifiedBy?: string; // 수정자ID
  url?: string; // media file URL
}

export interface CtProviderAccount {
  accountId?: number; // 계좌 ID
  providerId?: number; // 프로바이더 ID
  accountType?: AccountType; // 계좌 타입 (DEPOSIT_ACCOUNT : 보증금 계좌, RENT_ACCOUNT : 임대료 계좌, MANAGEMENT_ACCOUNT : 관리비 계좌)
  bankCode?: string; // 은행코드
  bankName?: string; // 은행명 (조회 시에만 유효)
  accountNumber?: string; // 계좌번호
  accountHolder?: string; // 예금주
  isDeleted?: boolean; // 삭제 여부
  createdDate?: string; // 등록일자
  createdBy?: string; // 등록자ID
  modifiedDate?: string; // 수정일자
  modifiedBy?: string; // 수정자ID
}

/**
 * 이용신청 등록
 */
export interface ContractAddModel {
  contractManage: {
    mbOrganizationId: number; // 조직(회사) id
    spaceProductId: number; // 공간상품 id (공간상품 관리)
    spaceProductType: ProductType; // 공간상품 타입
    spaceProductName: string; // 공간상품이름
  };
  contract: {
    contractApplyType?: ContractApplyType; // (신청)신청유형 (VISIT : 방문희망, CONTRACT : 계약희망)
    useStartTime?: string; // (신청)이용 시작 일시 (이용신청일 기준 2주 이내 선택 가능)
    useEndTime?: string; // (신청)이용 종료 일시
    useTerm?: number; // (신청)이용 신청기간
    applicantName?: string; // (신청)이용신청자 이름
    applicantCorporation?: string; // (신청)이용신청자 회사명
    isAgreeTerms?: boolean; // (신청)이용신청 약관동의 여부
  };
  contractMember: {
    inviteMobileNumber?: string; // 초대 휴대폰번호(E.164)
    memberNo?: string; // 회원번호
  };
}

/**
 * 이용신청 등록 response data
 */
export interface ContractAddResponseData {
  contractManageId: number; // 계약 관리 id
  contractId: number; // 계약 id
}

/**
 * 이용신청 수정 response data
 */
export interface ContractUpdateResponseData {
  contractId: number; // 계약 id
}

/**
 * 결제금액 산출 response data
 */
export interface CalculateAmountModel {
  spaceProductId: number; // 공간상품 ID
  useStartTime: string; // 이용 시작 일시
  useEndTime: string; // 이용 종료 일시
  payTime: number; // 과금 적용시간(분) : 분단위 반올림
  productRentMinute10: string; // 공간상품 10분이용료
  productRentDiscountMinute10: string; // 공간상품 10분할인이용료
  totalAmountWithVAT: string; // 합계(부가세 포함) : 총 공금가액 - 할인 금액 + 부가세
  supplyAmount: string; // 총 공급가액 : 공간상품 10분이용료 * 0.1 * 이용시간(분)
  discountAmount: string; // 할인 금액 : (공간상품 10분이용료 - 10분할인금액) * 0.1 * 이용시간(분)
  valueAddedTax: string; // 부가세 : (총공금가액-할인금액) * 0.1
}

/**
 * T타입 청구/결제 response data
 */
export interface ContractTimePaymentResponseData {
  onlineUrl: string; // PC 환경 결제 창 호출 URL
  mobileUrl: string; // Mobile 환경 결제창 호출 URl
}

// /**
//  * 청구정보 결제현황 ^(v1.3)
//  */
// export interface BillingContractBillModel {
//   prevBillId?: string; // 직전 청구ID (현재일 기준)
//   nextBillId?: string; // 다음 청구ID (현재일 기준)
//   overdueBillId?: string; // 직전 연체청구 ID (현재일 기준 한달 이내 발행)
//   billList?: BillModel[];
// }

/**
 * 청구정보 결제현황 ^(v1.4)
 */
export interface BillingContractBillModel {
  // prevBillId?: string; // 직전 청구ID (현재일 기준)
  // nextBillId?: string; // 다음 청구ID (현재일 기준)
  // overdueBillId?: string; // 직전 연체청구 ID (현재일 기준 한달 이내 발행)
  billList?: BillModel[];
}

/**
 * 신청/계약 결제내역 상세
 */
export interface ContractPaymentModel {
  paymentId?: string; // 결제내역 id
  pgcode?: string; // 결제수단 코드
  orderNo?: string; //가맹점 주문번호
  amount?: number; // 결제금액
  taxAmount?: number; // 부가세 금액
  // taxfreeAmount?: number; // 비과세 금액
  productName?: string; // 결제 상품명
  tid?: string; // 결제고유번호
  cid?: string; // 승인번호
  payInfo?: string; // 결제 부가정보
  transactionDate?: string; // 거래일시
  installMonth?: string; // 할부개월수
  cardInfo?: string; //마스킹(중간6자리) 카드번호 (일반결제(신용카드, 페이코) / 자동결제(신용카드, 페이코) 에만 전달)
  paymentCancel?: {
    paymentId?: string; // 결제내역 id
    tid?: string; // 결제고유번호
    cid?: string; // 승인번호
    cancelAmount?: number; // 취소 금액
    cancelDate?: string; // 취소일시
  };
  virtualAccount?: {
    paymentId?: string; //		결제내역 id
    accountNo?: string; //		가상계좌 번호
    accountName?: string; //		가상계좌 입금자명
    accountHolder?: string; //		가상계좌 예금주명
    bankCode?: string; //		가상계좌 은행 코드
    bankName?: string; //		가상계좌 은행명
    issueTid?: string; //		가상계좌 채번 승인번호
    expireDate?: string; //		가상계좌 채번 만료일 (YYYYMMDD)
    expireTime?: string; //		가상계좌 채번 만료시각 (HHMM)
  };
}

// v1.3
// export interface BillModel {
//   billId?: string; // 청구 id
//   billNumber?: string; // 청구번호
//   billTimeType?: "IMMEDIATE_PAYMENT" | "REGULAR_PAYMENT" | "OVERDUE_PAYMENT"; // 청구시점타입 (IMMEDIATE_PAYMENT : 즉시결제, REGULAR_PAYMENT : 정기결제, OVERDUE_PAYMENT : 연체결제)
//   billPayType?: "PAY" | "TAX"; // 청구결제타입 (PAY : 일반결제형, TAX : 세금계산서 발행)
//   billCustomerName?: string; // 청구고객명
//   billOrder?: number; // 청구회차
//   billIssueDate?: string; // 청구일자
//   billStartTime?: string; // 이용시작 일시(월별)
//   billEndTime?: string; // 이용종료 일시(월별)
//   beforeDiscountAmount?: string; // 할인전금액
//   discountAmount?: string; // 할인금액
//   billAmount?: number; // 청구금액
//   taxAmount?: number; // 부가세
//   overdueDate?: number; // 연체기간(일)
//   overdueAmount?: number; // 연체금액(원)
//   billPayStatus?: BillPayStatus; // 청구결제상태
//   isOverdueBill?: boolean; // 연체청구여부
//   transactionTime?: string; // 거래일시
//   isDeleted?: boolean; // 삭제여부 ( false인 값만 전달)
//   createdDate?: string; // 등록일시
//   createdBy?: string; // 등록자
//   modifiedDate?: string; // 수정일시
//   modifiedBy?: string; // 수정자
// }

export type SupplyType = "RENTAL" | "MNT"; // (RENTAL: 임대료, MNT: 관리비)
export type BillTimeType = "IMMEDIATE_PAYMENT" | "REGULAR_PAYMENT" | "OVERDUE_PAYMENT"; //  (IMMEDIATE_PAYMENT : 즉시결제, REGULAR_PAYMENT : 정기결제, OVERDUE_PAYMENT : 연체결제)
export type BillPayType = "PAY" | "TAX"; // (PAY : 일반결제형, TAX : 세금계산서 발행)
export type ReceiptTrDateTimeType = "DRAFT" | "ENABLED" | "DESABLED"; // 작성중(기본값), 활성화(노출), 비활성(비노출)

interface ProviderAccountModel {
  accountId: number; // 계좌 id
  providerId: number; // 프로바이더 ID
  accountType: string; // 계좌 타입
  bankCode: string; // 은행 코드
  accountNumber: string; // 계좌 번호
  accountHolder: string; // 계좌소유주
}

interface ReceiptModel {
  receiptId: number; // 수납 id
  billId: number; // 청구 id
  billCreditId: number; // 청구 입금 ID
  acctCreditId: number; // 계좌 입금 ID
  paymentId: number; // 결제 ID
  receiptAmount: number; // 수납 금액
  trDateTime: ReceiptTrDateTimeType; // 거래일시 (수납일시)
  overdueDays: number; // 적용 연체일수
  overdueAmount: number; // 적용 연체금액
  overdueRates: number; // 적용 연체요율
  isDeleted: boolean; // 삭제여부
  createdDate: string; // 생성일
  createdBy: string; // 생성자
  modifiedDate: string; // 수정일
  modifiedBy: string; // 수정자
}

export interface ReceiptBundleModel {
  billTotalAmount: number; // 청구금액
  receiptTotalAmount: number; // 수납 금액
  accruedOverdueAmount: number; // 확정 연체금액 (납부기한 이후 수납금액에 대한 연체료)
  billUnpaidAmount: number; // 미납 금액 (청구금액 - 수납금액)
  realtimeOverdueAmount: number; // 실시간 계산 연체금액 (수납 후 잔액 대상)
  totalOverdueDays: number; // 총 연체기간
  lastTrDateTime: string; // 마지막 거래일시
  receiptList: ReceiptModel[]; // 수납내역
}
export interface OriginBillModel {
  billId: string; // 청구 id
  contractId: string; // 계약 id
  providerId: string; // 프로바이더 id
  supplyType: SupplyType; // 공급유형
  billNumber: string; // 청구번호
  billTimeType: BillTimeType; // 청구시점타입
  billCustomerName: string; // 청구고객명
  billOrder: number; // 청구회차
  billIssueDate: string; // 청구일자
  billDueDate: string; // 청구마감일
  taxInvoiceIssueDate: string; // 세금계산서 발행일자
  billStartTime: string; // 이용시작 일시(월별)
  billEndTime: string; // 이용종료 일시(월별)
  baseAmount: number; // 기준가 (2022.11.24 추가) (구. beforeDiscountAmount)
  adjustAmount: number; // 조정가 (2022.11.24 추가) (구. discountAmount)
  totalAmount: number; // 합계액 (2022.11.24 추가) (구. billAmount)
  supplyAmount: number; // 공급가 (2022.11.24 추가)
  taxAmount: number; // 부가세
  parentBillId: string; // 원 청구서 id
  billPayStatus: BillPayStatus; // 청구결제상태
  isOverdueBill: boolean; // 연체청구여부
  billTitle: string; // 청구 제목
  receiptBundle: ReceiptBundleModel;
}

// v1.4
export interface BillModel {
  billOrder: number; // 청구회차
  requestSumAmount: number; // 납부 총금액 (당월 청구 합계 + 이월금액)
  monthSumAmount: number; // 당월 청구 합계 (당월 이용료/관리비 + 연체료)
  prevSumUnpaidAmount: number; // 이월 미납금액
  prevSumOverdueAmount: number; // 이월 연체금액 (실시간 + 확정 연체금액 또는 연체청구서 금액의 합)
  billSupplyType: SupplyType; // 공급유형 (RENTAL: 이용료, MNT: 관리비)
  billTotalAmount: number; // 당월 청구금액 (이용료/관리비)
  billPayStatus: BillPayStatus; // 청구결제상태
  billLastTrDateTime: string; // 마지막 거래일시
  overdueAmount: number; // 연체금액 (실시간 + 확정 연체금액 또는 연체청구서 금액)
  overduePayStatus: BillPayStatus; // 연체 청구 상태
  overdueLastTrDateTime: string; // 연체청구 마지막 거래일시 (실시간 계산 중이라면 상태값 없음)
  originBill: OriginBillModel; // 원청구서
  overdueBill: OriginBillModel; // 연체청구서
  providerAccountList: ProviderAccountModel; // 프로바이더 계좌정보
  recentBill: ScheduleRecentBill;
}

/**
 * 계약 한컴 오메가 공간
 */
export interface ContractHancomSpace {
  isExist?: boolean; // 한컴 공간 생성여부
  isUsable?: boolean; // 한컴 맵제작 완료여부
}

/**
 * 계약 한컴 오메가 공간 reseponse
 */
export interface ContractHancomSpaceResponse {
  errorCode?: string; // 에러 결과코드
  errorMessage?: string; // 에러 결과메시지
  data?: ContractHancomSpace;
}

/**
 * 계약 한컴 오메가 공간 생성 reseponse
 */
export interface CreateContractHancomSpaceResponse {
  errorCode?: string; // 에러 결과코드
  errorMessage?: string; // 에러 결과메시지
  data?: {
    isSuccess?: boolean;
  };
}

export interface AutoPayModel {
  paymentId: string;
  result: boolean;
  receipt: ReceiptModel;
}

// 페이레터 청구 자동결제 response 타입
export interface AutoPayResponse {
  content: AutoPayModel;
}

//TCT35 계약신청 타입
export interface ContractApplyRequest {
  productId: number;
  isAgreeTerms: boolean;
  useStartTime?: string;
  useEndTime?: string;
  applicantMemberNo?: string;
  applicantMobile?: string;
  applicantName?: string;
  applicantCorporation?: string;
  mbOrganizationId?: number;
  pgcode?: string;
  quotationId: number;
}

//TCT35 response
export type ContarctApplyResponse = {
  contractManageId: string; //	계약 관리 id
  contractId: string; //	계약 id
  bill: [
    {
      billId: string; //	청구 id
      scheduleId: string; //	스케줄 id
      contractId: string; //	계약 id
      supplyType: string; //	공급유형 (임대료, 관리비)
      billNumber: string; //	청구번호 ( * 미사용)
      pgcode: string; //	"결제방법코드  //creditcard: 신용카드 //virtualaccount: 가상계좌  banktransfer: 계좌이체"
      billCustomerName: string; //	청구 고객명
      billOrder: number; //	청구회차
      billIssueDate: string; //	청구일자
      billDueDate: string; //	청구 납부기한
      taxInvoiceIssueDate: string; //	세금계산서 발행일
      billStartTime: string; //	이용시작일시
      billEndTime: string; //	이용종료일시
      baseAmount: number; //		기준가
      adjustAmount: number; //		조정가
      totalAmount: number; //		합계액
      supplyAmount: number; //		공급가
      taxAmount: number; //		부가세
      parentBillId: string; //	원청구정보 (연체청구서인 경우 값 존재)
      billPayStatus: string; //	"청구결제상태 PAYMENT_READY: 결제대기 /PAYMENT_SUCCESS: 결제성공 PAYMENT_STOP: 결제중지"
      isOverdueBill: boolean; //		연체청구서 여부
      billTitle: string; //청구 제목
    },
  ];
};

import { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ProductGuideDetail, ProductGuideNetwork, ProductGuideNetworkCatalog } from "src/api/guide/guide-types";
import { useToast } from "src/pages/hooks/toast";
import LinkifyText from "../../../../components/LinkifyText";

type Props = {
  productGuide?: ProductGuideDetail;
};

// 와이파이 위치
type WifiGuideLocation = {
  id?: string; // 사용안내 ID
  location?: string; // 위치
  description?: string; // 설명
  wifiNetworks?: Array<WifiNetwork>; // 네트워크
};

// 와이파이 네트워크
type WifiNetwork = {
  id?: string; // 사용안내 네트워크 ID
  orderNums?: number; // 순서
  wifiName?: string; // Wifi
  wifiPassword?: string; // Wifi Password
  isVisiblePw?: boolean; // 비밀번호 보여주기 여부
};

/**
 * 공간 이용안내 목록 > 상세 화면 > 네트워크 연결 항목 템플릿
 */
const NetworkGuide = ({ productGuide }: Props) => {
  // 토스트
  const { openToast } = useToast();

  // 고객용 와이파이 안내 위치 목록
  const [customerWifiLocations, setCustomerWifiLocations] = useState<WifiGuideLocation[]>([]);

  // 방문자용 와이파이 안내 위치 목록
  const [visitorWifiLocations, setVisitorWifiLocations] = useState<WifiGuideLocation[]>([]);

  // ProductGuideNetworkCatalog[] => WifiGuideLocation[] 로 변환
  const convertWifiLocations = (networkCatalogs: ProductGuideNetworkCatalog[]) => {
    return networkCatalogs.map((catalog: ProductGuideNetworkCatalog) => {
      const wifiGuideLocation: WifiGuideLocation = {
        id: catalog?.id,
        location: catalog?.location,
        description: catalog?.description,
        wifiNetworks: (catalog?.productGuideNetworkList || []).map((network: ProductGuideNetwork) => {
          const wifiNetwork: WifiNetwork = {
            id: network.id,
            orderNums: network.orderNums,
            wifiName: network.wifiName,
            wifiPassword: network.wifiPassword,
            isVisiblePw: false, // 기본 비밀번호 노출 여부 false 로 바인딩
          };
          return wifiNetwork;
        }),
      };
      return wifiGuideLocation;
    });
  };

  useEffect(() => {
    if (productGuide?.isDisplayed === true) {
      // 고객용 와이파이 (비공개 네트워크(고객용))
      const customerWifiList = (productGuide?.networkCatalogList || []).filter((network: ProductGuideNetworkCatalog) => network?.isOpened === false);
      setCustomerWifiLocations(convertWifiLocations(customerWifiList));
      // 방문자용 와이파이 (공개용 네트워크(방문자용))
      const visitorWifiList = (productGuide?.networkCatalogList || []).filter((network: ProductGuideNetworkCatalog) => network?.isOpened === true);
      setVisitorWifiLocations(convertWifiLocations(visitorWifiList));
    } else {
      setCustomerWifiLocations([]);
      setVisitorWifiLocations([]);
    }
  }, [productGuide]);

  // 비밀번호 확인 클릭
  const clickViewWifiPassword = useCallback(
    (type: "CUSTOMER" | "VISITOR", wifiGuideLocationId: string, wifiNetworkId: string) => {
      const newWifiGuideLocations: Array<WifiGuideLocation> = [];
      let changedIsVisiblePw = false;

      const wifiGuideLocations = type === "CUSTOMER" ? customerWifiLocations : visitorWifiLocations;
      for (let i = 0; i < wifiGuideLocations.length; i++) {
        const currentLocation = wifiGuideLocations[i];
        if (currentLocation.id === wifiGuideLocationId) {
          const newWifiNetworks: Array<WifiNetwork> = [];
          for (let j = 0; j < (currentLocation?.wifiNetworks || []).length; j++) {
            const currentNetwork = (currentLocation?.wifiNetworks || [])[j];
            if (currentNetwork.id === wifiNetworkId) {
              changedIsVisiblePw = !currentNetwork.isVisiblePw; // 현재 네트워크 비밀번호 노출여부 변경
              newWifiNetworks.push({ ...currentNetwork, ...{ isVisiblePw: changedIsVisiblePw } });
            } else {
              newWifiNetworks.push(currentNetwork);
            }
          }
          const newWifiGuideLocation = { ...currentLocation, ...{ wifiNetworks: newWifiNetworks } };
          newWifiGuideLocations.push(newWifiGuideLocation);
        } else {
          newWifiGuideLocations.push(currentLocation);
        }
      }
      if (type === "CUSTOMER") {
        setCustomerWifiLocations(newWifiGuideLocations);
      } else {
        setVisitorWifiLocations(newWifiGuideLocations);
      }

      if (changedIsVisiblePw) {
        // 비밀번호 노출로 변경했을 경우
        openToast({ content: "비밀번호가 복사되었습니다" });
      }
    },
    [customerWifiLocations, visitorWifiLocations, openToast],
  );

  return (
    <div className="contents__item-content">
      {customerWifiLocations.length > 0 && (
        <div className="wifi-info">
          <h2>일반 사용자용</h2>
          {customerWifiLocations.map((wifiGuideLocation: WifiGuideLocation, index: number) => (
            <section key={index.toString()} className="wifi-info__wrap">
              <h3>-위치: {wifiGuideLocation.location}</h3>
              {(wifiGuideLocation.wifiNetworks || []).map((wifiNetwork: WifiNetwork, wifiNetworkIndex: number) => (
                <div key={wifiNetworkIndex.toString()} className="input-wrap">
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span>네트워크</span>
                    </div>
                    <div className="like-disabled-input__value">
                      <span className="font14 font-family-roboto font-weight-400">{wifiNetwork.wifiName}</span>
                    </div>
                  </div>
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span>비밀번호</span>
                    </div>
                    <div className="like-disabled-input__value">
                      {!wifiNetwork.isVisiblePw && <span className="password">●●●●●●●●●</span>}
                      {wifiNetwork.isVisiblePw && <span className="font16 font-family-roboto font-weight-700">{wifiNetwork.wifiPassword || ""}</span>}
                    </div>
                    <div className="like-disabled-input__suffix">
                      <CopyToClipboard text={wifiNetwork.wifiPassword || ""}>
                        <button
                          className={`visible-btn ${wifiNetwork.isVisiblePw ? "--active" : ""}`}
                          onClick={() => clickViewWifiPassword("CUSTOMER", wifiGuideLocation.id || "", wifiNetwork.id || "")}
                        ></button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              ))}
              <p className="desc">
                <LinkifyText text={wifiGuideLocation?.description} />
              </p>
            </section>
          ))}
        </div>
      )}

      {visitorWifiLocations.length > 0 && (
        <div className="wifi-info">
          <h2>방문자용</h2>
          {visitorWifiLocations.map((wifiGuideLocation: WifiGuideLocation, index: number) => (
            <section key={index.toString()} className="wifi-info__wrap">
              <h3>-위치: {wifiGuideLocation.location}</h3>
              {(wifiGuideLocation.wifiNetworks || []).map((wifiNetwork: WifiNetwork, wifiNetworkIndex: number) => (
                <div key={wifiNetworkIndex.toString()} className="input-wrap">
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span>네트워크</span>
                    </div>
                    <div className="like-disabled-input__value">
                      <span className="font14 font-family-roboto font-weight-400">{wifiNetwork.wifiName}</span>
                    </div>
                  </div>
                  <div className="like-disabled-input">
                    <div className="like-disabled-input__prefix">
                      <span>비밀번호</span>
                    </div>
                    <div className="like-disabled-input__value">
                      {!wifiNetwork.isVisiblePw && <span className="password">●●●●●●●●●</span>}
                      {wifiNetwork.isVisiblePw && <span className="font16 font-family-roboto font-weight-700">{wifiNetwork.wifiPassword || ""}</span>}
                    </div>
                    <div className="like-disabled-input__suffix">
                      <CopyToClipboard text={wifiNetwork.wifiPassword || ""}>
                        <button
                          className={`visible-btn ${wifiNetwork.isVisiblePw ? "--active" : ""}`}
                          onClick={() => clickViewWifiPassword("VISITOR", wifiGuideLocation.id || "", wifiNetwork.id || "")}
                        ></button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              ))}
              <p className="desc">
                <LinkifyText text={wifiGuideLocation?.description} />
              </p>
            </section>
          ))}
        </div>
      )}
    </div>
  );
};
export default NetworkGuide;

import Header from "src/components/layout/Header";
type Props = {
  onClose: Function;
};
const ServiceTypeGuidelines = ({ onClose }: Props) => {
  const text1 = `음료, 스낵, 샐러드 또는 샌드위치 취식만 가능하며, 그 외의 음식물 반입은 허용되지 않습니다.`;
  const text2 = `위의 내용을 포함한 기타 유의사항은 Taap 운영정책에서 확인하실 수 있습니다.`;

  return (
    <>
      <Header
        headerType="CLOSE"
        title="유의사항"
        onClickCloseButton={() => {
          onClose()!;
        }}
      />
      <section className="base-section-layout w-100">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap">
            <div className="base-section-layout__title-wrap">
              <ul className="ml10 mr10 pb10 font14 text-primary1">
                <li>{text1}</li>
                <li>{text2}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceTypeGuidelines;

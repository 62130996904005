import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, MediaServiceType } from "../public-types";
import { FileData, MediaFile } from "src/api/file/file-types";
import { ApiPath } from "..";

/**
 * TPM01. (무인증) 공통 미디어파일 목록
 */
export async function getPublicMediaFilesAsync(
  axios: AxiosInstance,
  params?: {
    serviceId: string | number;
    mediaServiceTypes: Array<MediaServiceType>;
  },
): Promise<AxiosResponse<ApiResponse<{ content: Array<MediaFile> }>>> {
  if (!params?.serviceId) {
    throw Error("serviceId is not found");
  }
  if (!params?.mediaServiceTypes || (params?.mediaServiceTypes || []).length === 0) {
    throw Error("mediaServiceTypes is not found");
  }
  return await axios.get("/api/court/pr/public/mediaFile", {
    params: {
      serviceId: params.serviceId,
      mediaServiceType: params.mediaServiceTypes.join(","),
    },
  });
}

// RFS03. 파일 업로드 처리
export async function uploadFilesAsync(
  axios: AxiosInstance,
  params?: {
    file: any;
    type?: "public" | "private";
  },
): Promise<AxiosResponse<ApiResponse<{ media: FileData }>>> {
  // type 없으면 기본 public 으로 업로드
  const path = ApiPath.file.upload.replace("{type}", params?.type || "public");
  if (!params?.file) {
    throw Error("업로드할 파일이 없습니다.");
  }
  return await axios.post(path, params.file);
}

/**
 * media file url 로 get 요청
 */
export async function getDownloadMediaFileAsync(
  axios: AxiosInstance,
  params?: {
    url: string;
  },
): Promise<AxiosResponse<any>> {
  if (!params?.url) {
    throw Error("url is not found");
  }
  const isIncludedDomain = params.url.startsWith(process.env.REACT_APP_PPOINT_BASEURL || "");
  const fileUrl = isIncludedDomain ? params.url.split(process.env.REACT_APP_PPOINT_BASEURL || "")[1] : params.url;
  return await axios.get(fileUrl, { responseType: "blob" });
}

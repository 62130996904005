import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "src/components";
import { useCourtAuth } from "src/components/court-auth";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useMemberInfoState } from "src/recoil/member/hook";
import { route } from "src/vars";

/**
 * 로그인 화면
 */
const Login = () => {
  const navigate = useNavigate();
  const { login } = useMemberInfoState();
  const { security } = useCourtAuth();

  useEffect(() => {
    if (security.status === "authenticated") {
      // 로그인 되어있으면 신청/계약 목록 화면으로 이동
      navigate(route.mainPath);
    }
  }, [security, navigate]);

  return (
    <>
      <MetaTag title="로그인" />
      <Header />
      <div className="my-page">
        <article className="before-login-contents">
          <h1>로그인</h1>
          <p>
            플레이하실 준비가 되셨나요?
            <br />
            로그인이 필요한 페이지입니다.
            <br />
            비회원도 '시작하기'로 회원가입 후 이용 가능합니다.
          </p>
          <BaseButton className="login-btn" title="시작하기" onClick={() => login()} />
        </article>
      </div>
    </>
  );
};
export default Login;

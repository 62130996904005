import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getBuildingsDetailsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getPublicProductGuideAsync } from "src/api/guide/guide-api";
import { ProductGuideDetail as ProductGuide } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useHeaderOpacity } from "src/pages/hooks/header-opacity";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
import EtcGuide from "./components/EtcGuide";
import NetworkGuide from "./components/NetworkGuide";

/**
 * 무인증 공간 이용안내 목록 > 상세 화면 (Taap 웹뷰)
 */
const PublicProductGuideDetail = () => {
  const initializedProductGuide = useRef(false);
  const initializedBuilding = useRef(false);

  const { goBack } = useNavigateGoBack();

  // path variable 공간상품 id
  const { productId, guideId } = useParams();

  // (무인증) 공간상품 사용안내 상세조회 api
  const { executeAsync: getPublicProductGuide } = useApiOperation(getPublicProductGuideAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // (무인증) 건물 상세정보 목록 api
  const { executeAsync: getBuildingsDetails } = useApiOperation(getBuildingsDetailsAsync, { noAuthenticationRequired: true, noHandleError: true });

  // 공간상품 사용안내 상세
  const [productGuide, setProductGuide] = useState<ProductGuide>();

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel | null>(null);

  const pageContainer = useRef<HTMLDivElement>(null);
  const startPoint = useRef<HTMLDivElement>(null);

  // 헤더 오퍼시티 적용
  const { opacityCount } = useHeaderOpacity({ pageContainer, startPoint });

  // 공간상품 사용안내 상세조회
  const fetchPublicProductGuide = useCallback(
    async (guideId: string) => {
      const { data } = await getPublicProductGuide({ guideId, isOpened: true });
      setProductGuide(data?.data?.content);
      if (!initializedProductGuide.current) {
        initializedProductGuide.current = true;
      }
    },
    [getPublicProductGuide],
  );

  // 건물 상세정보 조회
  const fetchBuildingsDetail = useCallback(
    async (buildingId: string | number) => {
      const { data } = await getBuildingsDetails({
        buildingIds: [buildingId],
      });
      if (data?.data?.content && (data?.data?.content || []).length > 0) {
        setBuilding((data?.data?.content || [])[0]?.building);
      }
      if (!initializedBuilding.current) {
        initializedBuilding.current = true;
      }
    },
    [getBuildingsDetails],
  );

  useEffect(() => {
    if (productId && guideId) {
      // 공간상품 사용안내 상세조회
      fetchPublicProductGuide(guideId);
    }
  }, [productId, guideId, fetchPublicProductGuide]);

  useEffect(() => {
    if (productGuide && productGuide?.buildingId) {
      // 건물 상세정보 조회
      fetchBuildingsDetail(productGuide.buildingId);
    }
  }, [productGuide]);

  return (
    <div>
      <MetaTag title="공간 이용안내" />
      {initializedProductGuide.current && initializedBuilding.current && (
        <>
          <Header headerType="BACK" title={productGuide?.subject} opacityCount={opacityCount} onClickCloseButton={() => goBack()} />
          <div className="space-info detail" ref={pageContainer}>
            <div className={`title-wrap`} ref={startPoint}>
              <h2>{productGuide?.subject}</h2>
              <p>{building?.buildingName}</p>
            </div>
            <section className="contents__section">
              {productGuide?.productGuideType === "PRODUCT_GUIDE_WIFI" ? (
                <NetworkGuide productGuide={productGuide} />
              ) : (
                <EtcGuide productGuide={productGuide} />
              )}
            </section>
          </div>
        </>
      )}
    </div>
  );
};
export default PublicProductGuideDetail;

import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { ScheduleData } from "src/api/reservation/reservation-type";
import { BaseModal, ConfirmModal } from "src/components";
import SwiperCalendar from "src/components/swiperCalendar/SwiperCalendar";
import { Modal } from "src/pages/visitor/visitor-types";
import { numberToStringWithComma } from "src/utils/common-util";

type Props = {
  date: string;
  selectedDate: (date: string) => void;
  scheduleList: ScheduleData[];
  onSelectSchedules: (data: ScheduleData) => void;
  selectedSchedule: ScheduleData[];
};

const FacilityReservation = ({ date, selectedDate, scheduleList, onSelectSchedules, selectedSchedule }: Props) => {
  const slotRef = useRef<HTMLDivElement>(null); //포커스를 위한 ref
  const calendarRef = useRef<HTMLDivElement>(null);
  // 현재 시간
  const calendarDate = useMemo(() => {
    const todayAndTime = moment().toDate();
    const _calendarDate = moment(date).toDate();

    if (moment(todayAndTime).isSame(_calendarDate, "D")) {
      return todayAndTime;
    } else {
      return _calendarDate;
    }
  }, [date]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const scrollToCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.scrollIntoView({ behavior: "smooth", block: "start" }); // 스크롤 부드럽게 처리
    }
  };
  const scrollToCurrentSlot = () => {
    if (slotRef.current) {
      // slotRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" }); // 스크롤 부드럽게 처리

      const findrow = scheduleList.find((item) => moment(item.startDate).isBefore(calendarDate) && moment(item.endDate).isAfter(calendarDate))?.slot;
      const currentWidth = (Number(findrow) - 1) * 46.99;
      const friendsContainer = slotRef.current;
      if (friendsContainer) {
        friendsContainer.scrollTo({
          top: 0,
          left: currentWidth,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    // scrollToCalendar();
    scrollToCurrentSlot();
    // setTimeout(() => scrollToCurrentSlot(), 500);
  }, [scheduleList]);

  // 현재 시간 기준으로 스크롤 이동

  const reservationHours = useMemo(() => {
    const list = scheduleList.map((item) => moment(item.startDate).format("HH"));
    const uniqueData: string[] = [];
    const seenIndices: Record<string, number> = {};

    for (let i = 0; i < list.length; i++) {
      const value: string = list[i];

      if (seenIndices[value] === undefined) {
        // 해당 값이 처음 나타난 경우
        seenIndices[value] = i;
        uniqueData.push(value);
      } else {
        // 이미 나온 값이라면 현재 값은 ""(빈 문자열)으로 처리
        uniqueData[i] = "";
      }
    }
    return uniqueData;
  }, [scheduleList]);

  const onClickTimes = (schedule: ScheduleData) => {
    if (schedule.isClosed) return;

    const isAvailable =
      (moment(schedule.startDate).isBefore(calendarDate) && moment(schedule.endDate).isAfter(calendarDate)) ||
      moment(schedule.startDate).isSameOrAfter(calendarDate);

    if (!isAvailable) {
      setAlertModal({ isOpen: true, message: "과거 시간을 선택할 수 없습니다" });
      return;
    }
    onSelectSchedules(schedule);
  };

  return (
    <section className="facility-info__calendar-time">
      <div
        ref={calendarRef}
        className="calendar-area" //
      >
        <h2>날짜를 선택해 주세요</h2>
        <SwiperCalendar
          onDateClick={(date) => {
            selectedDate(date);
          }}
        />
      </div>
      <div className="time-area">
        <h2>시간을 선택해 주세요</h2>
        <div
          className="time-picker" //
          ref={slotRef} // 스크롤 최상단 위치, 시간 선택 부분만 스크롤 할거면 여기 사용
        >
          <div className="time-picker__index">
            {reservationHours.map((hour, idx) => (
              <div key={idx}>
                <span className={Number(moment(calendarDate).format("HH")) > Number(hour) ? "text-primary4" : ""}>{hour}</span>
              </div>
            ))}
          </div>
          <div id="time-picker__grid" className="time-picker__grid">
            {scheduleList.map((item) => (
              <div
                //scrollIntoView 쓸거면 여기 해제
                // ref={moment(item.startDate).isBefore(calendarDate) && moment(item.endDate).isAfter(calendarDate) ? slotRef : null}
                className={selectedSchedule.find((time) => time.slot === item.slot) ? "--active" : item.isClosed === true ? "--disabled" : ""}
                key={item.slot}
                onClick={() => onClickTimes(item)}
              >
                {numberToStringWithComma(item.price)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <>
        {alertModal.isOpen && (
          <ConfirmModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
            <p className="font18 font-weight-600">{alertModal.message}</p>
          </ConfirmModal>
        )}
      </>
    </section>
  );
};

export default FacilityReservation;

import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  imgsrc?: string;
  url?: string;
  isReCAPTCHA?: boolean;
  naverLanguage?: string;
};

/**
 * SEO meta tag 컴포넌트
 * @param title : 기본 "Taap"
 */

const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

export default function MetaTag({ title = "TaapSpace", description, keywords, imgsrc, url, isReCAPTCHA = false, naverLanguage }: Props) {
  useEffect(() => {
    const grecaptchaBadge = document.querySelector(".grecaptcha-badge");
    if (!isReCAPTCHA && grecaptchaBadge) {
      grecaptchaBadge.remove();
    }
  }, [isReCAPTCHA]);
  return (
    <Helmet>
      <title>TaapSpace</title>
      <meta name="format-detection" content="telephone=yes" />

      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      {description && <meta property="og:description" content={description} />}
      {imgsrc && <meta property="og:image" content={imgsrc} />}
      {url && <meta property="og:url" content={url} />}

      {url && <link rel="canonical" href={url} />}
      {isReCAPTCHA && <script src={`https://www.google.com/recaptcha/enterprise.js?render=${reCAPTCHAsiteKey}&language=${naverLanguage}`}></script>}
      {naverLanguage && (
        <script
          id="navermap"
          type="text/javascript"
          src={`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.REACT_APP_NAVER_CLOUD_CLIENT_ID}&submodules=geocoder&language=${naverLanguage}`}
        ></script>
      )}
    </Helmet>
  );
}

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { BillModel, MemberContractModel } from "src/api/contract/contract-types";
import { numberToStringWithComma } from "src/utils/common-util";
import { BILL_SUPPLY_TYPE_MAP } from "src/utils/type-map";
import { isBillPaySuccess, totalAmount } from "../../contract-bill-types";
import { useMemo } from "react";

type Props = {
  selectedContract?: MemberContractModel;
  billItem: BillModel;
};

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 청구 내역 목록 > 회차별 청구 목록 아이템
 */
const BillItem = ({ selectedContract, billItem }: Props) => {
  const navigate = useNavigate();

  const billSupplyType = BILL_SUPPLY_TYPE_MAP[billItem?.billSupplyType];

  const billPayStatusText = useMemo(() => {
    switch (billItem.billPayStatus) {
      case "CONTRACT_CANCEL":
        return "해지";
      case "PAYMENT_CANCEL":
        return "결제 취소";
      case "PAYMENT_SUCCESS":
        return "납부";
      case "PAYMENT_FREE":
        return "무료";

      default:
        return "미납";
    }
  }, [billItem.billPayStatus]);

  return (
    <div className="base-section-layout__wrap">
      <div className="base-section-layout-line">
        <p className="base-section-layout-index">{billItem.originBill.billTitle}</p>
        <p className="base-section-layout-value">
          <button
            type="button"
            className="icon-right-btn pr18 font15 ic-chevron-right icon-gray"
            onClick={() => navigate(`/court/mypage/contracts/${selectedContract?.contractId}/bills/${billItem.billOrder}`)}
          >
            <b className="font15">{totalAmount(billItem)}</b> 원
          </button>
        </p>
      </div>
      {/* 원청구 */}
      <div className={`base-section-layout-line mb0 ${isBillPaySuccess(billItem.billPayStatus) ? "text-primary3" : "text-red"}`}>
        <p className="base-section-layout-index">
          {billSupplyType} {billPayStatusText}
          {/* {isBillPaySuccess(billItem.billPayStatus) ? (billItem?.billPayStatus === "PAYMENT_FREE" ? "무료" : "납부") : "미납"} */}
        </p>
        <p className="base-section-layout-value pr15">{numberToStringWithComma(billItem.originBill.totalAmount)} 원</p>
      </div>

      {/* 원청구의 실시간 연체료 */}
      {!billItem?.overdueBill && Number(billItem.overdueAmount || 0) > 0 && (
        <div className={`base-section-layout-line mb0 text-red`}>
          <p className="base-section-layout-index">실시간 연체료</p>
          <p className="base-section-layout-value pr15">{numberToStringWithComma(billItem.overdueAmount)} 원</p>
        </div>
      )}

      {/* 연체청구서 */}
      {billItem?.overdueBill && (
        <div className={`base-section-layout-line ${isBillPaySuccess(billItem.overdueBill?.billPayStatus) ? "text-primary3" : "text-red"}`}>
          <p className="base-section-layout-index">
            연체료{" "}
            {isBillPaySuccess(billItem.overdueBill?.billPayStatus)
              ? billItem.overdueBill?.billPayStatus === "PAYMENT_FREE"
                ? "무료"
                : "납부"
              : "미납"}
          </p>
          <p className="base-section-layout-value pr15">{numberToStringWithComma(billItem.overdueBill.totalAmount)} 원</p>
        </div>
      )}
    </div>
  );
};
export default BillItem;

import { ScheduleRecentBill } from "src/api/contract/contract-types";
import Header from "src/components/layout/Header";
import { numberToStringWithComma } from "src/utils/common-util";
import RefundPolicy from "../../serviceTypePayment/guide/RefundPolicy";
import { useState } from "react";
import { ConfirmModal } from "src/components";
import { Modal } from "src/pages/visitor/visitor-types";

type Props = {
  recentBill: ScheduleRecentBill;
  onClose: any;
  onClick: any;
};
const ContractCancel = ({ recentBill, onClose, onClick }: Props) => {
  // 확인 취소 버튼 있는 확인 모달

  return (
    <>
      <Header
        headerType="CLOSE"
        title="취소요청"
        onClickCloseButton={() => {
          if (onClose!) {
            onClose();
          }
        }}
      />
      <div className="contract-page">
        <article className="with-backward-floating">
          <RefundPolicy type="cancel" />
          {/* <section className={`base-section-layout top-divider`}>
            <div className="base-section-layout__container">
              <div className="base-section-layout__wrap">
                <div className="base-section-layout__title-wrap">
                  <h2>취소 및 환불 예상정보</h2>
                </div>

                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">총 결제금액</p>
                  <p className="base-section-layout-value">{numberToStringWithComma(Number(recentBill?.totalAmount || 0))} 원</p>
                </div>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">취소 수수료</p>
                  <p className="base-section-layout-value">{numberToStringWithComma(Number(recentBill?.taxAmount || 0))} 원</p>
                </div>
                <div className="base-section-layout-line">
                  <p className="base-section-layout-index text-primary3">환불 예정 금액</p>
                  <p className="base-section-layout-value">{numberToStringWithComma(Number(recentBill?.totalAmount || 0))} 원</p>
                </div>
              </div>
            </div>
          </section> */}
          <section className={`base-section-layout`}>
            <div className="mt20 w-100">
              <p className="pre-formatted flex-center-center">{`취소 및 환불 정책을 확인했으며 신청 취소에 동의합니다.`}</p>
              <p className="pre-formatted flex-center-center">{`신청 취소를 요청하시겠어요?`}</p>
            </div>
            <div className="base-floting-btn-wrap px0">
              <div className="cancel-modal">
                <div className="cancel-modal__contents">
                  <div className="cancel-modal__btn-wrap">
                    <button onClick={onClose} className="base-btn color-white mr10">
                      <p>취소</p>
                    </button>

                    <button onClick={() => onClick()} className="base-btn">
                      <p>확인</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
    </>
  );
};
export default ContractCancel;

import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { MediaFile } from "src/api/file/file-types";
import { BaseDatePicker, BaseTextInput } from "src/components";
import BaseFileUpload from "src/components/BaseFileUpload";

import { emailFormat, linePhoneFormat, onlyNumber } from "src/utils/common-util";

type Props = {
  mode: string;
  documentProofRequest: any;
  setDocumentProofRequest: any;
  proofError: any;
  setProofError: any;
};
const TaxInvoiceInfo = ({ mode, documentProofRequest, setDocumentProofRequest, proofError, setProofError }: Props) => {
  useEffect(() => {
    const check = _.cloneDeep(documentProofRequest);
    if (check.expenseProofType === null) {
      check.expenseProofType = "TAX_INVOICE";
      check.identityType = "CORP_NUM";
      setDocumentProofRequest(check);
    }
  }, [documentProofRequest, setDocumentProofRequest]);

  return mode === "edit" ? (
    <div className="w-100 pl30">
      <article>
        <section className="tax-calculate">
          <div className="container">
            <p className="index-title required">사업자 등록번호</p>
            <BaseTextInput
              value={documentProofRequest.identityNum}
              onChange={(value: string | undefined) => {
                const temp = _.cloneDeep(documentProofRequest);
                temp.identityNum = onlyNumber(String(value));
                setDocumentProofRequest(temp);
                if (value) {
                  const err = _.cloneDeep(proofError);
                  err.error.identityNum = false;
                  err.format.identityNum = false;
                  setProofError(err);
                }
              }}
              placeholder="숫자만 입력해 주세요 (필수)"
              onBlur={() => {
                const temp = _.cloneDeep(documentProofRequest);
                const pf = _.cloneDeep(proofError);
                console.log("temp.identityNum", temp.identityNum);
                if (temp.identityNum === "" || temp.identityNum === null) {
                  pf.error.identityNum = true;
                  pf.format.identityNum = false;
                } else {
                  pf.error.identityNum = false;
                  if (temp.identityNum.length !== 10) {
                    pf.format.identityNum = true;
                  } else {
                    pf.format.identityNum = false;
                  }
                }

                setProofError(pf);
              }}
            />
            {proofError.error.identityNum && <p className="mt5 font12 text-red">사업자등록번호를 입력해 주세요.</p>}
            {proofError.format.identityNum && <p className="mt5 font12 text-red">올바르지 않은 사업자 등록 번호 형식입니다.</p>}
          </div>
          <div className="container ">
            <p className="index-title required">사업자 등록증 사본</p>
            <BaseFileUpload
              type="PROOF"
              serviceTag={"BUSINESS_LICENSE"}
              isOnlyView={false}
              limit={1}
              fileList={documentProofRequest?.mediaList ?? []}
              setFiles={(files: MediaFile[]) => {
                // documentProofRequest 값을 최신값을 불러오지 못함. prev 값으로 처리
                setDocumentProofRequest((prev: any) => {
                  const next = _.cloneDeep(prev);
                  next.mediaList = [...(files as any[])];
                  next.mediaList.forEach((mediaFile: any) => {
                    mediaFile["orderNums"] = 1;
                    if (next.contractId && Number(next.contractId) > 0) {
                      mediaFile["cmdType"] = "U";
                    } else {
                      mediaFile["cmdType"] = "C";
                    }
                    mediaFile["isPrimary"] = true;
                  });

                  return next;
                });
                const err = _.cloneDeep(proofError);
                err.error.mediaList = false;

                setProofError(err);
              }}
            />
            {proofError.error.mediaList && <p className="mt5 font12 text-red">사업자등록증을 첨부해 주세요.</p>}
            {/* {proofError.format.mediaList && <p className="mt5 font12 text-red">사업자등록증을 첨부해 주세요.</p>} */}
          </div>
          <div className="container">
            <p className="index-title required">수신 이메일</p>
            <BaseTextInput
              value={documentProofRequest.taxInvoiceEmail}
              onChange={(value: string | undefined) => {
                const err = _.cloneDeep(proofError);
                err.error.taxInvoiceEmail = false;
                err.format.taxInvoiceEmail = false;
                setProofError(err);

                const temp = _.cloneDeep(documentProofRequest);
                const pt = /[ㄱ-ㅣ가-힣]/;
                if (!pt.test(String(value))) {
                  temp.taxInvoiceEmail = value;
                }

                setDocumentProofRequest(temp);
              }}
              onBlur={() => {
                const temp = _.cloneDeep(documentProofRequest);
                const pf = _.cloneDeep(proofError);
                if (emailFormat(temp.taxInvoiceEmail)) {
                  pf.format.taxInvoiceEmail = false;
                } else {
                  pf.format.taxInvoiceEmail = true;
                }
                setProofError(pf);
              }}
              placeholder="이메일을 입력해 주세요 (필수)"
            />
            {proofError.error.taxInvoiceEmail && <p className="mt5 font12 text-red">세금계산서 수신 이메일을 입력해 주세요</p>}
            {proofError.format.taxInvoiceEmail && <p className="mt5 font12 text-red">올바르지 않은 이메일 형식입니다.</p>}
          </div>
          <div className="container">
            <p className="index-title required">유선 전화번호</p>
            <BaseTextInput
              value={documentProofRequest.taxInvoicePhone}
              onChange={(value: string | undefined) => {
                const temp = _.cloneDeep(documentProofRequest);
                temp.taxInvoicePhone = onlyNumber(String(value));
                setDocumentProofRequest(temp);
                if (value) {
                  const err = _.cloneDeep(proofError);
                  err.error.taxInvoicePhone = false;
                  err.format.taxInvoicePhone = false;
                  setProofError(err);
                }
              }}
              onBlur={() => {
                const temp = _.cloneDeep(documentProofRequest);
                const pf = _.cloneDeep(proofError);
                if (linePhoneFormat(temp.taxInvoicePhone)) {
                  pf.format.taxInvoicePhone = false;
                } else {
                  pf.format.taxInvoicePhone = true;
                }
                setProofError(pf);
              }}
              placeholder="숫자만 입력해 주세요 (필수)"
            />
            {proofError.error.taxInvoicePhone && <p className="mt5 font12 text-red">세금계산서 발행을 위해 유선 전화번호를 입력해 주세요.</p>}
            {proofError.format.taxInvoicePhone && <p className="mt5 font12 text-red">올바르지 않은 전화번호 형식입니다.</p>}
          </div>
          <div className="container">
            <p className="index-title required">계산서 작성일자</p>
            <BaseDatePicker
              value={documentProofRequest.taxInvoiceDate ? moment(documentProofRequest.taxInvoiceDate).toDate() : null}
              className="text-left"
              onChange={(value: Date) => {
                const temp = _.cloneDeep(documentProofRequest);
                temp.taxInvoiceDate = value;
                setDocumentProofRequest(temp);
                if (value) {
                  const err = _.cloneDeep(proofError);
                  err.error.taxInvoiceDate = false;
                  err.format.taxInvoiceDate = false;
                  setProofError(err);
                }
              }}
              placeholder="작성일자를 입력해 주세요 (필수)"
            />
            {proofError.error.taxInvoiceDate && <p className="mt5 font12 text-red">세금계산서 작성 희망일을 선택해 주세요.</p>}
          </div>
        </section>
      </article>
    </div>
  ) : (
    <div></div>
  );
};

export default TaxInvoiceInfo;

import { AxiosInstance, AxiosResponse } from "axios";
import { ApiPath } from "..";
import { PriceModel, PriceParams } from "../product/product-types";
import { ApiResponse } from "../public-types";
import {
  AcknowledgeReservation,
  HolidayPeriodModel,
  HolidayPeriodParams,
  QuotationModel,
  QuotationParams,
  QuotationRequest,
  ReservationPeriodModel,
  ReservationPeriodParams,
  ReservationQueryParams,
} from "./reservation-type";

/**TIC01 - (무인증) 휴무기간 조회 */
export async function getHolidaysPeriodAsync(
  axios: AxiosInstance,
  params?: HolidayPeriodParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: HolidayPeriodModel[] }>>> {
  return await axios.get(ApiPath.holiday.period, {
    params,
  });
}

/**TIR01 - (무인증) 예약가능기간 조회 */
export async function getReservationPeriodAsync(
  axios: AxiosInstance,
  params?: ReservationPeriodParams,
): //
Promise<AxiosResponse<ApiResponse<{ content: ReservationPeriodModel[] }>>> {
  return await axios.get(ApiPath.reservation.period, {
    params,
  });
}

/**TIP01. (무인증) 요금 조회*/
export async function getPriceAsync(
  axios: AxiosInstance,
  params?: PriceParams, //
): Promise<AxiosResponse<ApiResponse<{ content: PriceModel[] }>>> {
  return await axios.get(ApiPath.prices.list, {
    params,
  });
}

/**TIQ02 - (무인증)견적서 등록*/
export async function postQuotationAsync(
  axios: AxiosInstance,
  params?: QuotationRequest, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel }>>> {
  const data = {
    quotationItemList: params!.quotationItemList,
  };

  return await axios.post(
    ApiPath.quotation.add,
    {
      quotation: data,
    },
    { headers: { "X-Recaptcha-Token": params!.token } },
  );
}

//TIQ01 - (무인증)견적서 목록(상세)
export async function getQuotationListPublicAsync(
  axios: AxiosInstance,
  params?: QuotationParams, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel[] }>>> {
  return await axios.get(ApiPath.quotation.list.public, {
    params: { quotationNo: params?.quotationNo },
    headers: { "X-Recaptcha-Token": params?.token || "" },
  });
}

//TIQ01 - (인증)견적서 목록(상세)
export async function getQuotationListAsync(
  axios: AxiosInstance,
  params?: QuotationParams, //
): Promise<AxiosResponse<ApiResponse<{ content: QuotationModel[] }>>> {
  return await axios.get(ApiPath.quotation.list.auth, {
    params: { quotationNo: params!.quotationNo },
  });
}

/**TRV10. (무인증) 공용 공간 예약 점유 현황 조회*/
export async function getReservedTimesAsync(
  axios: AxiosInstance,
  params?: ReservationQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: AcknowledgeReservation[] }>>> {
  return await axios.get("/api/court/rv/public/acknowledged/reservations", {
    params,
  });
}

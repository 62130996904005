import { ProductGuideDetail } from "src/api/guide/guide-types";
import { MarkdownViewer } from "src/components";

type Props = {
  productGuide?: ProductGuideDetail;
};

/**
 * 공간 이용안내 목록 > 상세 화면 > 일반항목 또는 주차 관련 안내 템플릿
 */
const EtcGuide = ({ productGuide }: Props) => {
  return (
    <div className="contents__item-content">
      <div>{productGuide?.description && <MarkdownViewer value={productGuide.description} />}</div>
    </div>
  );
};
export default EtcGuide;

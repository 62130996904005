import moment from "moment";
import { YmdFormat } from "src/utils/common-util";
// import { RenderCalendar } from "./RenderCalendar";
// import { data } from "./data";

type Dates = {
  date: string;
};

const SlideItem = ({ month, selectedDay, setSelectedDay, onDateClick }: any) => {
  let days = [];

  //일단 1일부터 3n 일까지 반복..
  // TODO : Taap 캘린더 룰과 동일해야함
  // 1) 매년 1월 1일부터 12개월을 노출한다 (확인필요)
  // 2) 각 슬라이더의 마지막 주는 다음 슬라이드의 첫번째 주에서 반복한다 **
  const firstDate = moment(month).startOf("months").startOf("weeks");

  for (let i = 0; i < 7 * 5; i++) {
    days.push({
      date: firstDate.format("YYYY/MM/DD"),
    });
    firstDate.add(1, "day");
  }

  function handleClickDay(e: React.MouseEvent<HTMLButtonElement>, day: Dates) {
    e.preventDefault();
    setSelectedDay(day.date);
    onDateClick(moment(day.date).format(YmdFormat.WITH_TIME_ZONE));
  }
  // const findClass = (day: Dates) => {
  //   let colIndex = new Date(day.date).getDay();
  //   return `col-start-${colIndex + 1}`;
  // };

  return (
    <div className="swiper-slide">
      <div className="days-container">
        {days?.map((day, dayIdx) => (
          <div key={day.date.toString()} className={`day`}>
            {moment(day.date).format("D") === "1" && selectedDay !== day.date && (
              <div className={`absolute-month ${moment(day.date).isBefore(moment().subtract(1, "day")) ? "disabled" : ""}`}>
                <span>{moment(day.date).format("M")} /</span>
              </div>
            )}
            <button
              type="button"
              disabled={moment(day.date).isBefore(moment().subtract(1, "day"))}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickDay(e, day)}
              className={day.date === selectedDay ? "--active" : ""}
            >
              {moment(day.date).format("D")}
            </button>
            {selectedDay !== day.date && day.date === moment().format("YYYY/MM/DD") && <div className="absolute-today">오늘</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlideItem;

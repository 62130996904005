import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBillingContractBillsAsync } from "src/api/contract/contract-api";
import { BillingContractBillModel, BillModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { numberToStringWithComma } from "src/utils/common-util";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 이용료 납부 일정 화면
 */
const PaymentScheduleList = () => {
  const initializedRef = useRef(false);

  const navigate = useNavigate();

  // path variable 계약 id
  const { contractId } = useParams();

  // 청구정보 결제현황 조회 api
  const { executeAsync: getBillingContractBills } = useApiOperation(getBillingContractBillsAsync);

  // 청구정보 결제현황
  const [billingContractBill, setBillingContractBill] = useState<BillingContractBillModel>();

  console.log(`billingContractBill`, billingContractBill);

  // 원청구 목록
  const billList = useMemo(() => {
    if (!billingContractBill || (billingContractBill?.billList || []).length === 0) return [];
    return (billingContractBill?.billList || [])
      .filter((bill: BillModel) => !bill?.originBill.isOverdueBill) // 원청구
      .sort((a: BillModel, b: BillModel) => Number(a?.billOrder || 0) - Number(b?.billOrder || 0));
  }, [billingContractBill]);

  // 청구정보 결제현황 조회
  const fetchBillingContractBills = useCallback(
    async (contractId: number | string) => {
      const { data } = await getBillingContractBills({ contractId });
      setBillingContractBill(data?.data);

      if (!initializedRef.current) {
        initializedRef.current = true;
      }
    },
    [getBillingContractBills],
  );

  useEffect(() => {
    if (contractId) {
      // 청구정보 결제현황 조회
      fetchBillingContractBills(contractId);
    }
  }, [contractId, fetchBillingContractBills]);

  return (
    <>
      <MetaTag title="마이페이지" />
      <Header
        headerType="CLOSE"
        title="이용료 납부 일정"
        onClickCloseButton={() => {
          navigate(-1);
        }}
      />
      <div className="contract-page">
        <h1 className="base-title">이용료 납부 일정</h1>

        {initializedRef.current && (
          <article className="contract-history-montly">
            {billList.length === 0 ? (
              <section className="contents-empty">
                <p>납부일정이 없습니다</p>
              </section>
            ) : (
              <section className="base-section-layout">
                <div className="base-section-layout__container gap20 border-bottom-gray pt20">
                  {billList?.map((bill: BillModel, index: number) => (
                    <div key={index} className="base-section-layout__wrap">
                      <div className="base-section-layout-line">
                        <div className="base-section-layout-index flex-center">
                          <div className="minmax64">
                            <span>{bill.billOrder}회차</span>
                          </div>
                          <p>{moment(bill.originBill.billIssueDate).format("YYYY.MM.DD")}</p>
                        </div>
                        <p className="base-section-layout-value">
                          <b>{numberToStringWithComma(bill.originBill.totalAmount)}</b> 원
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </article>
        )}
      </div>
    </>
  );
};

export default PaymentScheduleList;

import { orderBy } from "lodash";
import QueryString from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BuildingFloorModel, BuildingModel, BuildingRoomModel } from "src/api/building/building-types";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { getPublicProductGuidesAsync } from "src/api/guide/guide-api";
import { ProductGuideByList } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { getPublicProductAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import noimg from "src/assets/images/icons_large_noimg_building.svg";
import { BaseBottomSheet } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import { useHeaderOpacity } from "src/pages/hooks/header-opacity";
import usePostMessage from "src/pages/hooks/post-message";
import { getResizedImageUrl } from "src/utils/common-util";

// 건물의 외관 media file
type BuildingMediaFile = {
  buildingId: string;
  mediaFile: MediaFile;
};

/**
 * 무인증 공간 이용안내 목록 화면 (알림톡 링크 용)
 */
const PublicProductGuideList = () => {
  const initializedProductRef = useRef<boolean>(false);
  const initializedProductGuidesRef = useRef<boolean>(false);

  const pageContainer = useRef<HTMLDivElement>(null);
  const startPoint = useRef<HTMLDivElement>(null);

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = noimg;
  };

  const location = useLocation();
  const navigate = useNavigate();

  const { postMessageGoBackNavigation } = usePostMessage();

  // path variable 공간상품 id
  const { productId } = useParams();

  // query parameter
  const [searchParams] = useSearchParams();

  // (무인증) 공간상품 정보 조회 api
  const { executeAsync: getPublicProduct } = useApiOperation(getPublicProductAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 공통 미디어파일 목록 api
  const { executeAsync: getPublicMediaFiles } = useApiOperation(getPublicMediaFilesAsync, { noAuthenticationRequired: true, noHandleError: true });

  // (무인증) 공간상품 사용안내 목록조회 api
  const { executeAsync: getPublicProductGuides } = useApiOperation(getPublicProductGuidesAsync, {
    noAuthenticationRequired: true,
    noHandleError: true,
  });

  // 공간상품 상세
  const [product, setProduct] = useState<ProductModel | null>(null);

  // 건물의 외관 media file 목록
  const [buildingMediaFiles, setBuildingMediaFiles] = useState<BuildingMediaFile[]>([]);

  // 공간상품 사용안내 목록
  const [productGuides, setProductGuides] = useState<ProductGuideByList[]>([]);

  // 선택되어있는 건물 id
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>("");

  // 대표건물 id
  const [primaryBuildingId, setPrimaryBuildingId] = useState<string>("");

  // query parameter buildingId
  const searchParamBuildingId = useMemo(() => {
    return searchParams.get("buildingId");
  }, [searchParams]);

  // navigate query parameter buildingId 변경
  const navigateWithBuildingId = useCallback(
    (buildingId: string) => {
      const queryParams = QueryString.parse(location.search, { ignoreQueryPrefix: true });
      const newQueryParams = { ...queryParams, ...{ buildingId } };
      navigate(
        {
          pathname: location.pathname,
          search: QueryString.stringify(newQueryParams),
        },
        { replace: true },
      );
    },
    [navigate, location],
  );

  // 해당 공간상품의 건물 목록
  const buildingList: BuildingModel[] = useMemo(() => {
    if (!product || (product?.buildingList || []).length === 0) return [];
    // 대표건물
    const primaryBuilding = product?.buildingList?.find((building) => String(building?.id) === String(primaryBuildingId));
    // 대표건물 제외 건물명 오름차순으로 정렬
    const notPrimaryBuildingList = orderBy(
      product?.buildingList?.filter((building) => String(building?.id) !== String(primaryBuildingId)),
      ["", "buildingName"],
      ["asc"],
    );
    // 최상단 대표 건물, 이후 건물은 건물명 오름차순
    if (primaryBuilding) {
      return [primaryBuilding, ...notPrimaryBuildingList];
    }

    return notPrimaryBuildingList;
  }, [product, primaryBuildingId]);

  // 선택된 건물
  const selectedBuilding: BuildingModel | undefined = useMemo(() => {
    if (!selectedBuildingId || !product || (product?.buildingList || []).length === 0) return undefined;
    return (product?.buildingList || [])
      .map(({ building }) => building)
      .find((building) => String(building?.id || "") === String(selectedBuildingId));
  }, [product, selectedBuildingId]);

  // 건물 선택 bottom sheet 오픈 여부
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState<boolean>(false);

  // 헤더 오퍼시티 적용
  const { opacityCount } = useHeaderOpacity({ pageContainer, startPoint });

  // 공간상품 상세 조회
  const fetchProduct = useCallback(
    async (productId: string) => {
      const { data } = await getPublicProduct({ productId });

      const _product = data?.data?.content || null;
      setProduct(_product);

      if (_product && (_product?.buildingList || []).length > 0) {
        // 대표 건물 찾기
        let _primaryBuilding: BuildingModel | null = null;
        for (let i = 0; i < (_product?.buildingList || []).length; i++) {
          const { building } = (_product?.buildingList || [])[i];
          if (building) {
            // 공간상품의 대표 건물 호실
            const primaryBuildingFloor: BuildingFloorModel | undefined = (building?.buildingFloorList || []).find(
              (buildingFloor: BuildingFloorModel) => {
                return (buildingFloor?.buildingRoomList || []).find(
                  (buildingRoom: BuildingRoomModel) => buildingRoom.isPrimary, // 대표
                );
              },
            );
            if (!!primaryBuildingFloor) {
              _primaryBuilding = building;
            }
          }
        }
        if (_primaryBuilding) {
          // 대표 건물 id
          const _primaryBuildingId = String(_primaryBuilding.id);
          setPrimaryBuildingId(_primaryBuildingId);

          if (searchParamBuildingId) {
            // search param 에 buildingId 가 있으면 해당 건물 선택
            setSelectedBuildingId(searchParamBuildingId);
          } else {
            // 대표 건물이 선택되어있게
            setSelectedBuildingId(_primaryBuildingId);
          }
        }
      }

      if (!initializedProductRef.current) {
        initializedProductRef.current = true;
      }
    },
    [getPublicProduct, searchParamBuildingId],
  );

  // 공통 미디어파일 목록 조회
  const fetchPublicMediaFiles = useCallback(
    async (buildingIds: string[]) => {
      const _buildingMediaFiles: BuildingMediaFile[] = [];
      for (let i = 0; i < buildingIds.length; i++) {
        const { data } = await getPublicMediaFiles({
          serviceId: buildingIds[i],
          mediaServiceTypes: ["MEDIA_BUILDING"], // 건물 외관 ( 건물 대표 카테고리 )
        });
        if (data?.data?.content && (data?.data?.content || []).length > 0) {
          const primaryMediaFile = data.data.content.find((v: MediaFile) => v.isPrimary === true);
          if (primaryMediaFile) {
            // 건물 외관의 대표 이미지
            _buildingMediaFiles.push({ buildingId: buildingIds[i], mediaFile: primaryMediaFile });
          }
        }
      }
      setBuildingMediaFiles(_buildingMediaFiles);
    },
    [getPublicMediaFiles],
  );

  // 공간상품 사용안내 목록조회
  const fetchPublicProductGuides = useCallback(
    async (productId: string | number, buildingId: string | number) => {
      const { data } = await getPublicProductGuides({ productId, buildingId });
      const _productGuides = data?.data?.content || [];
      setProductGuides(_productGuides);

      if (!initializedProductGuidesRef.current) {
        initializedProductGuidesRef.current = true;
      }
    },
    [getPublicProductGuides],
  );

  useEffect(() => {
    if (productId) {
      // 공간상품 상세 조회
      fetchProduct(productId);
    }
  }, [productId, fetchProduct]);

  useEffect(() => {
    if (buildingList && buildingList.length > 0) {
      // 공통 미디어파일 목록 조회
      const buildingIds = buildingList.map((building: any) => String(building.id));
      fetchPublicMediaFiles(buildingIds);
    }
  }, [buildingList, fetchPublicMediaFiles]);

  useEffect(() => {
    if (product?.id && selectedBuildingId) {
      // 공간상품 사용안내 목록조회
      fetchPublicProductGuides(product.id, selectedBuildingId);
    }
  }, [selectedBuildingId, product, fetchPublicProductGuides]);

  // 건물의 외관 media file 목록 중 해당 건물 파일 찾기
  const findBuildingMediaFile = useCallback(
    (buildingId: string) => {
      return buildingMediaFiles.find((v: BuildingMediaFile) => v.buildingId === buildingId);
    },
    [buildingMediaFiles],
  );

  // 바텀시트에서 건물 선택 변경
  const onChangeSelectedBuilding = useCallback((buildingId: string) => {
    setIsOpenBottomSheet(false);
    // setSelectedBuildingId(buildingId);
    navigateWithBuildingId(buildingId);
  }, []);

  return (
    <div>
      <MetaTag title="공간 이용안내" />
      <Header headerType="BACK" title="공간 이용안내" opacityCount={opacityCount} onClickBackButton={() => postMessageGoBackNavigation()} />
      <div className="space-info" ref={pageContainer}>
        <div className={`title-wrap`} ref={startPoint}>
          <span>{product?.productName}</span>
          <br />
          <h1>공간 이용안내</h1>
          {initializedProductRef.current && (
            <div className="w-100 overflow-hidden">
              <button
                className={`chevron-down-btn text-left mb30 ${isOpenBottomSheet ? "--active" : ""}`}
                onClick={() => {
                  setIsOpenBottomSheet(true);
                }}
                disabled={!buildingList || buildingList.length <= 1}
              >
                <p className="ellipsis">{selectedBuilding?.buildingName}</p>
              </button>
              {/* <div className="support-banner" onClick={()=>{}}>
              <p>공간 이용에 불편함이 있나요?</p>
              <p>1:1문의</p>
            </div> */}
            </div>
          )}
        </div>

        <BaseBottomSheet
          isOpen={isOpenBottomSheet}
          onClose={() => setIsOpenBottomSheet(false)}
          title={"건물을 선택해주세요"}
          indexTitle={product?.productName}
          className="scrollable"
        >
          <div className="space-select-sheet">
            <div className="space-list">
              {buildingList?.map((building: BuildingModel, index: number) => (
                <div
                  key={index}
                  className="space-list__item"
                  onClick={() => {
                    onChangeSelectedBuilding(String(building.id));
                  }}
                >
                  <div className="space-list__item-wrap">
                    <img
                      src={getResizedImageUrl(findBuildingMediaFile(String(building.id))?.mediaFile?.url)}
                      onError={handleImgError}
                      alt="building-img"
                    />
                    <div className="space-list__item-info">
                      <p>{building?.buildingName}</p>
                      <span>
                        {(building?.addressList || []).length > 0
                          ? `${(building.addressList || [])[0].address} ${(building.addressList || [])[0].addressDetail}`
                          : ""}
                      </span>
                    </div>
                    <div className="space-list__item-selected">
                      {String(selectedBuildingId) === String(building.id) && <div className="space-list__item-checked"></div>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </BaseBottomSheet>

        {initializedProductGuidesRef.current && productGuides.length === 0 ? (
          <section className="contents-empty">
            <div>
              <div className="icon ic-none mb30"></div>
              <p>공간 이용안내 정보가 없습니다</p>
            </div>
          </section>
        ) : (
          <section className="list-section">
            {productGuides.map((productGuide: ProductGuideByList, index: number) => (
              <div
                key={index}
                className="list-section__item"
                onClick={() => navigate(`/court/product/${productId}/public/guides/${productGuide.id}`)}
              >
                <p>{productGuide.subject}</p>
              </div>
            ))}
          </section>
        )}
      </div>
    </div>
  );
};
export default PublicProductGuideList;

import { useCallback, useState } from "react";
import BaseFullModal from "src/components/BaseFullModal";
import ServiceTypeGuidelines from "./guide/ServiceTypeGuidelines";
import ServiceTypeRefundPolicy from "./guide/ServiceTypeRefundPolicy";

const ServiceTypeGuide = () => {
  const [guideOpen, setGuideOpen] = useState(false);
  const [guideRefund, setRefundOpen] = useState(false);
  const closeGuide = useCallback(() => setGuideOpen(false), [setGuideOpen]);
  const closeRefund = useCallback(() => setRefundOpen(false), [setRefundOpen]);

  return (
    <div className="base-section-layout__wrap">
      <div className="base-section-layout-line">
        <div className="base-section-layout-index cursor-pointer w-100">
          <div className="mt15 no-wrap" onClick={() => setGuideOpen(true)}>
            <div className="flex-center-between">
              <span>유의사항</span>
              <div className="arrow-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="base-section-layout-line">
        <div className="base-section-layout-index cursor-pointer w-100">
          <div className="mt15 no-wrap " onClick={() => setRefundOpen(true)}>
            <div className="flex-center-between">
              <span>환불규정</span>
              <div className="arrow-right"></div>
            </div>
          </div>
        </div>
      </div>
      {guideOpen && <BaseFullModal isOpen={guideOpen} children={<ServiceTypeGuidelines onClose={closeGuide} />} />}
      {guideRefund && <BaseFullModal isOpen={guideRefund} children={<ServiceTypeRefundPolicy onClose={closeRefund} />} />}
    </div>
  );
};
export default ServiceTypeGuide;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "src/components";
import { useWindowDimension } from "src/pages/hooks/window-dimension";

interface TaapCourtMatchingPath {
  taap: string;
  court: string;
}

interface PathMatchingResult {
  result: boolean;
  variable: {
    [props: string]: string;
  };
}

const appScheme = process.env.REACT_APP_TAAP_APP_SCHEME!;
const appLink = `${appScheme}://${window.location.href.substring(window.location.href.indexOf("/front/taap") + 1)}`;
const redirectWebUrl = `https://static.taapspace.kr/link/taap.html?target=${window.location.href}`;

const taapCourtMatchingPaths: Array<TaapCourtMatchingPath> = [
  {
    taap: "/taap/my/contracts/:contractId",
    court: "/court/mypage/contracts/:contractId",
  },
  {
    taap: "/taap/my/contracts/:contractId/bills/:billOrder",
    court: "/court/mypage/contracts/:contractId/bills/:billOrder",
  },
];

/**
 * /front/taap 으로 접근시 (taap 의 universal link) redirect 핸들링하는 화면
 */
const Taap = () => {
  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * useRef
   */
  const intervalRef = useRef<NodeJS.Timer>();

  /**
   * useState
   */
  // 이동할 경로
  const [courtPath, setCourtPath] = useState<string>();

  // 높이값
  const { height } = useWindowDimension();

  // 카운트
  const [seconds, setSeconds] = useState<number>(5);

  /**
   * useMemo
   */
  // 타이틀 마진
  const titleMarginTop = useMemo(() => {
    return Math.max(Math.min(40, height - 726), -50);
  }, [height]);

  /**
   * 함수
   */
  // 동일 path 인지 체크
  const sameEndPath = (target: string, source: string): PathMatchingResult => {
    var targetPaths = target.split("/").reverse();
    var sourcePaths = source
      .split("/")
      .filter((item) => !!item)
      .reverse();

    const variable: { [props: string]: string } = {};

    const result = sourcePaths.every((item, index) => {
      if (item.startsWith(":") && targetPaths?.[index]) {
        variable[item.substring(1)] = targetPaths?.[index];
        return true;
      }

      return item === targetPaths?.[index];
    });

    return {
      result,
      variable,
    };
  };

  // path 생성 (path variable 포함)
  const generatePath = (url: string, variable: { [props: string]: string }) => {
    const [path, queryParam] = url.split("?");
    const pathItems = path.split("/");

    const resultPath = pathItems
      .map((item) => {
        if (item.startsWith(":")) {
          return variable[item.substring(1)];
        }
        return item;
      })
      .join("/");

    if (!queryParam) {
      return resultPath;
    }

    return `${resultPath}?${queryParam}`;
  };

  // 리다이렉트 처리
  const redirect = useCallback(async () => {
    const targetUrl = window.location.href;

    // 1. Deep link 연결 시도 (업이 설치된 경우 이동됨)
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        window.location.replace(appLink);
      }, 100);

      setTimeout(() => {
        resolve();
      }, 500);
    });

    // 2-1. Court 페이지 리다이렉트 필요한한 페이지인지 확인
    let pathMatchingResult: PathMatchingResult = { result: false, variable: {} };
    const taapCourtMatchingPathIndex = taapCourtMatchingPaths.findIndex(({ taap }) => {
      const [targetPath] = targetUrl.split("?");
      const result = sameEndPath(targetPath, taap);
      if (result.result) {
        pathMatchingResult = result;
        return true;
      }
      return false;
    });

    // 2-2. 매칭되는 court path가 있을경우 court 페이지로 이동
    console.debug("taapCourtMatchingPathIndex", taapCourtMatchingPathIndex);
    if (taapCourtMatchingPathIndex >= 0) {
      const courtPath = generatePath(taapCourtMatchingPaths[taapCourtMatchingPathIndex].court, pathMatchingResult.variable);
      console.debug("courtPath", courtPath);

      setCourtPath(courtPath);
    }

    // 3. static page 로 이동
    else {
      window.location.replace(redirectWebUrl);
    }
  }, []);

  // 해당 경로로 이동하기
  const onClickMoveButton = () => {
    if (courtPath) {
      navigate(courtPath);
    }
  };

  /**
   * useEffect
   */
  // 리다이렉트 처리
  useEffect(() => {
    redirect();
  }, [redirect]);

  // 카운트다운
  useEffect(() => {
    if (!courtPath && intervalRef.current) {
      return;
    }

    intervalRef.current = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 0) {
          console.debug("seconds", prev);
          console.debug("seconds clearInterval");
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
          return prev;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      console.debug("useEffect return clearInterval");
      clearInterval(intervalRef.current);
    };
  }, [courtPath]);

  // 페이지 자동 이동
  useEffect(() => {
    if (courtPath && seconds === 0) {
      navigate(courtPath);
    }
  }, [courtPath, navigate, seconds]);

  // courtPath 가 없으면 표시 내용 없음
  if (!courtPath) {
    return <></>;
  }

  return (
    <div className="taap-page" style={{ height: height }}>
      <div className="taap-contents">
        <div className="title" style={{ marginTop: titleMarginTop }}>
          Play work with Taap
        </div>
        <div className="taap-icon"></div>
        <p className="taap-desc">
          출입부터 예약, 방문자 초대, 불편사항 접수까지
          <br />더 스마트한 라이프를 지원합니다.
        </p>
      </div>
      <div className="redirect-area">
        <div className="redirect-desc">
          <div className="redirect-desc-icon"></div>
          <div className="redirect-desc-text">{seconds}초 후에 페이지가 자동으로 이동합니다</div>
        </div>
        <BaseButton title="해당 페이지로 이동하기" onClick={onClickMoveButton} />
      </div>
    </div>
  );
};
export default Taap;
